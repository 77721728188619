.feature-box-one {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 18px -3px rgba(6, 16, 35, 0.08);
  border-radius: 10px;
  text-align: center;
  padding: 30px 15px;
  transition: all 0.5s ease;
  position: relative;
  z-index: 1;
  height: 100%;
  /* cursor: pointer; */
}

.feature-box-one::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../Img/SchoolMS/hover.gif);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
}
.feature-box-one:hover::before {
  opacity: 0.09;
  visibility: visible;
}
.feature-box-one .icon {
    width: 100px;
    height: 100px;
    border: 5px solid #7c00ff;
    display: block;
    margin: 0 auto 20px;
    border-radius: 50%;
    line-height: 80px;
}
.feature-box-one .icon img {
  width: 50px;
}
.feature-box-one h3 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
}
.feature-box-one h3::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 40px;
  height: 2px;
  background: #884ae3;
  transform: translateX(-50%);
}
.feature-box-one p {
  margin: 0;
  font-size: 16px;
}

.feature-box-one.light-yellow h3::before {
  background: #ffb400;
}
.feature-box-one.light-yellow .icon {
  border-color: #ffb400;
}
.feature-box-one.navi-blue h3::before {
  background: #0a00ff;
}
.feature-box-one.navi-blue .icon {
  border-color: #0a00ff;
}
.feature-box-one.green h3::before {
  background: #b9ff00;
}
.feature-box-one.green .icon {
  border-color: #b9ff00;
}
.feature-box-one.orange h3::before {
  background: #ff8600;
}
.feature-box-one.orange .icon {
  border-color: #ff8600;
}
.feature-box-one.light-orange h3::before {
  background: #009fff;
}
.feature-box-one.light-orange .icon {
  border-color: #009fff;
}
.feature-box-one.light-pink h3::before {
  background: #ff004e;
}
.feature-box-one.light-pink .icon {
  border-color: #ff004e;
}
.feature-box-one.pink h3::before {
  background: #ff00f7;
}
.feature-box-one.pink .icon {
  border-color: #ff00f7;
}
.feature-box-one.black h3::before {
  background: #00004c;
}
.feature-box-one.black .icon {
  border-color: #00004c;
}
