.harsafe-pricing-section {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap {
    padding-top: 30px;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing {
    background: #f8f8f8;
    border-radius: 10px;
    padding: 45px 55px 70px;
    margin-top: 30px;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing::before {
    content: "";
    position: absolute;
    right: -38%;
    top: -42%;
    border: 30px solid #ffffff;
    width: 445px;
    height: 445px;
    border-radius: 50%;
    z-index: -1;
  }
  
  @media only screen and (max-width: 1399px) {
    .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing {
      padding: 45px 30px 50px;
    }
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-badge .title {
    font-size: 36px;
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    color: #0e0e0e;
  }
  
  @media only screen and (max-width: 575px) {
    .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-badge .title {
      font-size: 24px;
    }
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price .currency {
    font-size: 30px;
    line-height: 54px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 600;
    color: var(--color-primary);
    display: inline-block;
    position: relative;
    bottom: -10px;
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price .currency {
      font-size: 25px;
    }
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price .price {
    font-size: 90px;
    line-height: 54px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 600;
    display: inline-block;
    color: var(--color-primary);
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price .price span {
    font-size: 18px;
    font-weight: 500;
    color: #959595;
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-price .price {
      font-size: 60px;
    }
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content {
    padding-top: 55px;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-list li {
    font-size: 18px;
    line-height: 30px;
  }
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-list {
    list-style: none;
    padding: 0;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-list li + li {
    margin-top: 10px;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-btn {
    padding-top: 40px;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-btn .btn {
    height: 50px;
    line-height: 48px;
    box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
    padding: 0 50px;
  }
  
  @media only screen and (max-width: 1399px) {
    .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-btn .btn {
      margin-right: 15px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing .pricing-content .pricing-btn .btn {
      padding: 0 30px;
      font-size: 16px;
    }
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active {
    background: linear-gradient(-180deg, var(--color-primary) 0%, #ff00b1 100%);
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active::before {
    opacity: 0.2;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-badge .title {
    color: #ffffff;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-price .currency {
    color: #ffffff;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-price .price {
    color: #ffffff;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-price .price span {
    color: #ffffff;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-content .pricing-list li {
    color: #ffffff;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-content .pricing-list li i {
    color: #ffffff;
  }
  
  .harsafe-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing.active .pricing-content .pricing-btn .pricing-contact {
    color: #5149f3;
  }
  