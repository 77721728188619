
.harsafe-service-section {
  padding-top: 50px;
}
.service-content-wrap {
    padding-top: 45px;
  }
  
  @media only screen and (max-width: 767px) {
    .service-content-wrap {
      padding-top: 20px;
    }
  }
  
  .service-item {
    background: #ffffff;
    box-shadow: 0px 43px 100px 0px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    border-top: 10px solid var(--color-primary);
    transform: translate3d(0, 0, 0);
    padding: 30px 40px 40px;
    margin-top: 30px;
    transition: all 0.3s linear;
  }
  
  @media only screen and (max-width: 1399px) {
    .service-item {
      padding: 30px 30px 40px;
    }
  }
  
  .service-item.service-01,
  .service-item.service-03 {
    margin-top: 55px;
  }
  
  @media only screen and (max-width: 1199px) {
    .service-item.service-01,
    .service-item.service-03 {
      margin-top: 30px;
    }
  }
  
  .service-item .service-img img {
    height: 80px;
  }
  
  .service-item .service-content .title {
    font-size: 24px;
    line-height: 30px;
    padding-right: 15px;
    margin-top: 20px;
  }
  .service-item .service-content .title a {
    text-decoration: none;
    color: var(--color-primary);
  }
  
  @media only screen and (max-width: 1399px) {
    .service-item .service-content .title {
      font-size: 22px;
      padding-right: 0;
    }
  }
  
  .service-item .service-content p {
    margin-top: 15px;
  }
  
  .service-item:hover {
    transform: translateY(-5px) translate3d(0, -5px, 0);
  }
  
  .service-item-05 {
    background: #ffffff;
    border-radius: 10px;
    padding: 40px 51px 45px;
    margin-top: 30px;
  }
  
  @media only screen and (max-width: 1399px) {
    .service-item-05 {
      padding: 40px 35px 45px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .service-item-05 {
      padding: 40px 30px 45px;
    }
  }
  
  .service-item-05 .service-img img {
    transition: all 0.3s linear;
  }
  
  .service-item-05 .service-content .title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-top: 25px;
  }
  
  .service-item-05 .service-content .title:hover a {
    color: #11ddf5;
  }
  
  .service-item-05 .service-content p {
    font-size: 14px;
    line-height: 30px;
    margin-top: 15px;
  }
  
  .service-item-05:hover .service-img img {
    transform: scale(1.1);
  }