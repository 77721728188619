section.ready_game_cover {
    /* background: linear-gradient(235deg, rba(0, 9, 14, 0) 0%, rgba(0, 9, 18, 0) 100%), url(../images/on-demand-solution/banner-bg1.jpg); */
    /* background: #f2f7fc; */
    background-image: radial-gradient(
        at center right,
        #2196f3 20%,
        #1e2d4a 100%
    );
    padding: 140px 0px 90px;
    /* background: -webkit-linear-gradient(right, #244640 40%, #345777 80%); */
    color: #f0f0f0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* margin-top: 80px; */

    /* new css animated background  */
    background: linear-gradient(122deg, #f18383, #448dd9, #d97878, #5196dd);
    background-size: 400% 400%;
    animation: gradientBG 7s ease infinite forwards;
}
@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.game_cover_content .row {
    align-items: center;
}
.game_cover_hd span {
    font-size: 80px;
    padding-bottom: 20px;
    font-weight: bold;
    color: #FF8A00;
    letter-spacing: 1px;
}
.game_cover_hd span.btxt {
    color: #336fbf;
}
.game_cover_hd span.gtxt {
    color: #48bfc4;
}
.game_cover_desc p {
    font-size: 22px;
}
.game_cover_link {
    margin-top: 30px;
}
a.game_cover_btn {
    display: inline-block;
    background-image: linear-gradient(#ffd546, #ffd546);
    padding: 10px 16px;
    color: #b9b9b9;
    font-size: 16px;
    border-radius: 5px;
    margin: 0px 10px 0px 0px;
}

.game_cover_right {
    padding-top: 20px;
}
.game_cover_left h2 {
    font-size: 24px;
    font-weight: 100;
    margin-bottom: 6px;
    color: deepskyblue;
}
.game_cover_img {
    background: url(../../Img/dshield/hero-right.png);
    background-size: contain;
    background-position: center;
    height: 500px;
    background-repeat: no-repeat;
}
.game_cover_im {
    background: url(../../Img/dshield/DShield_Logo.png);
    background-size: contain;
    background-position: 28%;
    height: 150px;
    background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
    .game_cover_left {
        text-align: center;
    }
    .game_cover_hd span{
        font-size: 60px;
        font-weight: 700;
        text-align: center;
        padding: 0px 0px 0px;
        margin: 0px 0px 10px;
    }
    .game_cover_im {
        background-position: center;
    }
    .game_cover_link {
        margin-top: 30px;
        text-align: center;
    }
}
