
:root {
  --color-primary: #ff0000;
  --color-primary-20: #fff6f5;
}

html::-webkit-scrollbar {
  display: none;
}


.section, .main-wrapper {
  float: left;
  width: 100%;
}
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}



/*Input Field & Select*/
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]),
textarea {
  border: 1px solid #ebebeb;
  box-shadow: none;
  color: #415674;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 0;
  padding: 10px 25px;
  max-width: 100%;
  width: 100%;
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  transition: all 0.3s linear;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):focus,
textarea:focus {
  outline: none;
  border-color: var(--color-primary);
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}


/*--
/*  2.3 - Button CSS
/*----------------------------------------*/
.btn-margin {
  margin-top: 40px;
}

.btn-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.btn-wrap .btn {
  margin: 5px;
}

.btn {
  display: inline-block;
  overflow: hidden;
  transform: perspective(0) translateZ(0);
  background: linear-gradient(195deg, var(--color-primary) 0%, #ff4242 100%);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  border-color: transparent;
  color: #ffffff !important;
  border-radius: 5px;
  font-size: 18px;
  /* font-family: "Barlow", sans-serif; */
  font-weight: 700 !important;
  height: 60px;
  line-height: 56px;
  padding: 0px 70px;
  border: 0;
  border-style: solid;
  box-shadow: none;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.btn::before {
  content: "";
  background: #0e0e0e;
  height: 50%;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
}

.btn::after {
  content: "";
  background: #0e0e0e;
  height: 50%;
  width: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
}

.btn:focus, .btn:focus:active {
  box-shadow: none;
}

.btn:hover {
  color: #ffffff !important;
}

.btn:hover::before {
  width: 100%;
  right: 0;
  left: auto;
}

.btn:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

.btn-3 {
  height: 50px;
  line-height: 45px;
  font-size: 14px;
  padding: 0 40px;
  background: var(--color-primary);
  color: #ffffff;
}

.btn-4 {
  height: 50px;
  line-height: 48px;
  font-size: 18px;
  padding: 0 45px;
  background: #11ddf5;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  color: #ffffff;
}

.btn-4:hover {
  background: #ffffff;
  color: #11ddf5;
}

.btn-round {
  border-radius: 100px;
}

.btn-square {
  border-radius: 0;
}

[class*="btn-icon-"] i {
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  line-height: 28px;
  text-align: center;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.btn-icon-left {
  padding-left: 69px;
}

.btn-icon-left i {
  left: 0;
  right: auto;
}

.btn-icon-right {
  padding-right: 69px;
}

.btn-icon-right i {
  left: auto;
  right: 0;
}

.btn-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #ffffff;
}

.btn-primary:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn-secondary {
  background-color: #000c29;
  border-color: #000c29;
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: #000c29;
  border-color: #000c29;
}

.btn-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #0e0e0e;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
}

.btn-white::after {
  background: var(--color-primary);
}

.btn-white::before {
  background: var(--color-primary);
}


.section-title .title.white {
  color: #ffffff;
}

.section-title .sub-title {
  font-size: 14px;
  line-height: 30px;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  color: var(--color-primary);
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.section-title .sub-title.color-2 {
  color: #ff5538;
}

.section-title .sub-title.color-3 {
  color: var(--color-primary);
}

.section-title .sub-title.white {
  color: #ffffff;
}

/*--Section Title Two--*/
.section-title2 {
  max-width: 690px;
  margin: 0 auto;
}


.section-padding-02 {
  padding-top: 120px;
}

@media only screen and (max-width: 1199px) {
  .section-padding-02 {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .section-padding-02 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-02 {
    padding-top: 60px;
  }
}

/*--Section Title--*/
.section-title .title {
  font-size: 48px;
  line-height: 54px;
  /* font-family: "Barlow", sans-serif; */
  font-weight: 600;
  color: #0e0e0e;
}

@media only screen and (max-width: 767px) {
  .section-title .title {
    font-size: 30px;
    line-height: 36px;
  }
}

.section-title .sub-title {
  font-size: 14px;
  line-height: 30px;
  /* font-family: "Jost", sans-serif; */
  font-weight: 600;
  color: var(--color-primary);
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

