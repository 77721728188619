
.harsafe-choose-us-section {
    background: url(../../../Img/bg/choose-us-bg3.jpg);
}

.choose-us-item-02 {
    background: #ffffff;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 40px 40px;
    /* margin-top: 30px; */
    position: relative;
    height: 100%;
  }
  
  .choose-us-content-wrap {
    margin-top: 60px;
  }
  
  .choose-us-item-02 .choose-us-img img {
    transition: all 0.3s linear;
    filter: invert(100%) sepia(173%) saturate(6877%) hue-rotate(0deg) brightness(100%) contrast(150%) !important;
  }
  
  .choose-us-item-02 .choose-us-content .title {
    font-size: 22px;
    line-height: 30px;
    margin-top: 20px;
  }
  
  .choose-us-item-02 .choose-us-content p {
    font-size: 16px;
    line-height: 30px;
    padding-top: 20px;
  }
  
  .choose-us-item-02:hover .choose-us-img img {
    transform: scale(0.9);
  }
  .choose-us-item-02 .choose-us-content .title a {
    color: var(--color-primary);
    text-decoration: none;
  }
  