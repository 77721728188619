.harsafe-brand-section {
  float: none;
}

.brand-title {
    font-size: 26px;
    font-weight: 500;
    font-family: "Jost", sans-serif;
    color: #4c4d56;
    margin-top: -7px;
  }
  
  @media only screen and (max-width: 575px) {
    .brand-title {
      font-size: 20px;
    }
  }
  
  .brand-title span {
    color: var(--color-primary);
    font-weight: 700;
  }
  
  .brand-active {
    max-width: 1070px;
    width: 100%;
    margin: 45px auto 0;
    overflow: hidden;
  }
  .brand-active-about {
    margin: 45px auto;
  }
  .harsafe-brand-section-02 .brand-wrapper {
    border-top: 1px solid #e1e1e1;
    padding-top: 20px;
    /* padding-bottom: 120px; */
  }
  
  .harsafe-brand-section-02 .swiper-container-pointer-events {
    touch-action: pan-y;
  }
  .harsafe-brand-section-02 .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  
  .harsafe-brand-section-02 .single-brand img {
    transition: 0.3s;
    opacity: 0.5;
    filter: grayscale(100);
  }
  .harsafe-brand-section-02 .single-brand img:hover {
    opacity: 1;
    filter: grayscale(0);
    
  }
  .brand-active-about-react {
    padding: 0 !important;
    border: none !important;
    margin: 45px auto;
  }