/*--
/*  13 - Team CSS Start
/*----------------------------------------*/
.team-content-wrap {
  padding-top: 70px;
}

.single-team {
  position: relative;
  height: 100%;
}

.single-team .team-img {
  position: relative;
  display: block;
  max-width: 220px;
  height: 220px;
  /* height: 100%; */
  margin: auto;
  padding: 3px;
}
.single-team .team-img::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(-180deg, #ff00b1 0%, var(--color-primary) 100%);
  border-radius: 50%;
  z-index: -1;
}

.single-team .team-img img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
  margin: auto;
  display: block;
}

.single-team .aboutUs_team_block {
  background: #fff;
  box-shadow: 0 0 100px #ccc;
  padding: 20px 13px;
  text-align: center;
  margin: -15px 0 0;
  position: relative;
  height: 50%;
}
.single-team .aboutUs_team_block::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(-180deg, #ff00b1 0%, var(--color-primary) 100%);
  border-radius: 50%;
  z-index: -1;
}

.single-team .aboutUs_team_block::before {
  position: absolute;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 60%;
  height: 6px;
  background: linear-gradient(-170deg, #ff00b1 0%, var(--color-primary) 100%);
  transform: translateX(-50%);
}

.single-team .aboutUs_team_block h2 {
  font-size: 24px;
  color: var(--color-primary);
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}

.single-team .aboutUs_team_block h3 {
  font-size: 18px;
  color: #1a2e35;
  font-weight: 600;
}
.single-team .aboutUs_team_block p {
  font-size: 16px;
  color: #555;
  font-weight: 400;
  line-height: normal;
}

.single-team:hover .team-content .team-social .social li a:hover {
  transform: translateY(-5px);
}

/*--
  /*  13 - Team CSS End
  /*----------------------------------------*/

/* aboutUs_gleam Section CSS Start  */

.aboutUs_gleam {
  background: url(https://suffescom.co/assets/images/mission-background.webp);
}
/* .aboutUs_gleam {
    padding: 100px 0;
    margin-bottom: 0
} */

.aboutUs_gleam .aboutUs_gleam_content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.aboutUs_gleam .aboutUs_gleam_content .left_part {
  width: 35%;
  position: relative;
}
@media (min-width: 1199px) {
  .aboutUs_gleam.right-img .aboutUs_gleam_content .left_part {
    order: 2;
  }
  .aboutUs_gleam.right-img .aboutUs_gleam_content .right_part {
    padding-right: 110px;
    padding-left: 0;
  }
  .aboutUs_gleam.right-img
    .aboutUs_gleam_content
    .left_part
    .left_part_images
    img {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 200px;
  }
  .aboutUs_gleam.right-img
    .aboutUs_gleam_content
    .left_part
    .left_part_images:before {
    left: 20px;
    top: 20px;
  }
  .aboutUs_gleam.right-img
    .aboutUs_gleam_content
    .left_part
    .left_part_images:before {
    border-radius: 0 0 0 400px;
  }
  .aboutUs_gleam.right-img .left_part_content {
    right: unset;
    left: 60px;
  }
}

.aboutUs_gleam .aboutUs_gleam_content .left_part .left_part_images {
  width: 100%;
  position: relative;
}

.aboutUs_gleam .aboutUs_gleam_content .left_part .left_part_images:before {
  position: absolute;
  content: "";
  left: -20px;
  top: -20px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(-180deg, #ff00b1 0%, var(--color-primary) 100%);
  border-radius: 0 0 400px 0;
}

.aboutUs_gleam .aboutUs_gleam_content .left_part .left_part_images img {
  width: 100%;
  position: relative;
  z-index: 9;
  border-bottom-right-radius: 200px;
  height: 500px;
  object-fit: cover;
  object-position: top;
}

.left_part_content {
  position: absolute;
  background: #fff;
  padding: 20px 25px;
  width: 100%;
  max-width: 347px;
  bottom: -35px;
  z-index: 99;
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(0 0 0/20%);
  /* min-height: 130px; */
  right: 0px;
}

.left_part_content h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  background: linear-gradient(-180deg, #ff00b1 0%, var(--color-primary) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 10px;
}

.left_part_content p {
  font-size: 15px;
  color: #707679;
  font-weight: 700;
  margin: 0;
}

.left_part_content p.aboutUs_linkedIn {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: baseline;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.left_part_content p.aboutUs_linkedIn span {
  margin: 0 10px 0 0;
  position: absolute;
  right: 34px;
  bottom: 5px;
  display: contents;
}
.left_part_content p.aboutUs_linkedIn .link-link {
  width: 36px;
}
.left_part_content p.aboutUs_linkedIn .link-link .libkdi {
  width: 100%;
}
.aboutUs_gleam .aboutUs_gleam_content .right_part {
  width: 65%;
  padding-left: 60px;
}

.aboutUs_gleam .aboutUs_gleam_content .right_part h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin: 0 0 20px;
  position: relative;
  padding-bottom: 15px;
  color: #202020;
}

.aboutUs_gleam .aboutUs_gleam_content .right_part h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: #202020;
  width: 143px;
  height: 3px;
}

.aboutUs_gleam .aboutUs_gleam_content .right_part p {
  font-size: 16px;
  color: #707679;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}


@media (max-width: 992px) {
  .aboutUs_gleam .aboutUs_gleam_content .left_part {
    width: 100%;
  }
  .aboutUs_gleam .aboutUs_gleam_content .right_part {
    width: 100%;
    padding: 0;
padding-top: 60px;
  }
  .aboutUs_gleam {
    padding: 40px 0;
  }
  .aboutUs_gleam .aboutUs_gleam_content .right_part h2 {
    font-size: 32px;
    line-height: 36px;
  }
}

/* @media (max-width: 880px) {
  .aboutUs_gleam .aboutUs_gleam_content .left_part {
    width: 100%;
  }
  .aboutUs_gleam .aboutUs_gleam_content .right_part {
    width: 100%;
    padding: 0;
padding-top: 100px;
  }
} */


/* aboutUs_gleam Section CSS End */
