
.contact-wcstm {
    background: url(../../Img/bg/contact-shape.png);
}

.harsafe-contact-section .contact-wrap {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 60% 100%;
  }
  
  .harsafe-contact-section .contact-wrap .contact-info .section-title .title {
    position: relative;
    padding-left: 45px;
  }
  
  .harsafe-contact-section .contact-wrap .contact-info .section-title .title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: var(--color-primary);
    height: 150px;
    width: 4px;
    transform: translateY(-50%);
  }
  
  .harsafe-contact-section .contact-wrap .contact-info ul {
    padding: 0;
    padding-top: 15px;
      list-style: none;
      margin: 0;  
  }
  
  .harsafe-contact-section .contact-wrap .contact-info ul li {
    margin-top: 15px;
    padding-right: 65px;
  }
  
  .harsafe-contact-section .contact-wrap .contact-info ul li:first-child {
    border-top: 0;
  }
  
  .harsafe-contact-section .contact-wrap .contact-info ul li .contact-info-item .contact-info-icon i {
    font-size: 30px;
    color: var(--color-primary);
  }
  
  @media only screen and (max-width: 575px) {
    .harsafe-contact-section .contact-wrap .contact-info ul li .contact-info-item .contact-info-icon i {
      font-size: 42px;
    }
  }
  
  .harsafe-contact-section .contact-wrap .contact-info ul li .contact-info-item .contact-info-text {
    margin-left: 20px;
  }
  
  .harsafe-contact-section .contact-wrap .contact-info ul li .contact-info-item .contact-info-text .title {
    font-size: 24px;
    line-height: 30px;
    color: #0e0e0e;
  }
  
  .harsafe-contact-section .contact-wrap .contact-info ul li .contact-info-item .contact-info-text p {
    font-size: 16px;
    line-height: 30px;
    color: #0e0e0e;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form {
    background: #f8f8f8;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 110px;
  }
  
  @media only screen and (max-width: 1399px) {
    .harsafe-contact-section .contact-wrap .contact-form {
      margin-left: 40px;
    }
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-contact-section .contact-wrap .contact-form {
      margin-left: 15px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-contact-section .contact-wrap .contact-form {
      margin-left: 0;
      margin-top: 60px;
    }
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap {
    padding: 55px 40px;
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap {
      padding: 40px;
    }
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap {
    margin-bottom: 30px;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap .sub-title {
    font-size: 14px;
    line-height: 30px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color-primary);
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap .title {
    font-size: 42px;
    line-height: 54px;
    font-weight: 600;
    color: #333333;
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap .title {
      font-size: 32px;
      line-height: 48px;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .heading-wrap .title {
      font-size: 28px;
      line-height: 40px;
    }
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form {
    margin-top: 25px;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
    height: 55px;
    line-height: 55px;
    border: 1px solid #ebebeb;
    padding-left: 15px;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-webkit-input-placeholder {
    opacity: 0.95;
    font-size: 14px;
    color: #4c4d56;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-moz-placeholder {
    opacity: 0.95;
    font-size: 14px;
    color: #4c4d56;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-moz-placeholder {
    opacity: 0.95;
    font-size: 14px;
    color: #4c4d56;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-ms-input-placeholder {
    opacity: 0.95;
    font-size: 14px;
    color: #4c4d56;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form textarea {
    height: 135px;
    border: 1px solid #ebebeb;
    padding-left: 15px;
    resize: none;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form textarea::-webkit-input-placeholder {
    opacity: 0.95;
    font-size: 14px;
    color: #4c4d56;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form textarea:-moz-placeholder {
    opacity: 0.95;
    font-size: 14px;
    color: #4c4d56;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form textarea::-moz-placeholder {
    opacity: 0.95;
    font-size: 14px;
    color: #4c4d56;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .single-form textarea:-ms-input-placeholder {
    opacity: 0.95;
    font-size: 14px;
    color: #4c4d56;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .form-btn {
    margin-top: 25px;
  }
  
  .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .form-btn .btn {
    font-size: 16px;
    width: 100%;
    height: 55px;
    line-height: 50px;
    margin-bottom: 10px;
  }
  
  @media only screen and (max-width: 575px) {
    .harsafe-contact-section .contact-wrap .contact-form .contact-form-wrap .form-btn .btn {
      padding: 0 45px;
    }
  }
  
  .harsafe-contact-section-02 .contact-wrap .contact-info .section-title .title::before {
    background: var(--color-primary);
  }
  
  .harsafe-contact-section-02 .contact-wrap .contact-info ul li .contact-info-item .contact-info-icon i {
    color: var(--color-primary);
  }
  
  .harsafe-contact-section-02 .contact-wrap .contact-form {
    background: #ffffff;
    box-shadow: 0px 0px 139px 0px rgba(0, 0, 0, 0.1);
  }
  
  .harsafe-contact-section-02 .contact-wrap .contact-form .contact-form-wrap .heading-wrap .sub-title {
    color: var(--color-primary);
  }
  
  .contact-map-section .contact-map-wrap {
    margin-bottom: -8px;
  }
  
  .contact-map-section .contact-map-wrap iframe {
    height: 550px;
    width: 100%;
    filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
  }
  