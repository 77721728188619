
.bg-imgss {
    background: url(../../../Img/bg/page-banner.jpg);
}


.page-banner-section {
    padding-top: 85px;
    min-height: 670px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .page-banner-section .shape-1 {
    position: absolute;
    left: -32%;
    top: -35%;
    z-index: -1;
  }
  
  .page-banner-section .shape-2 {
    position: absolute;
    left: -11%;
    top: -30%;
    z-index: -1;
  }
  
  .page-banner-section .shape-3 {
    position: absolute;
    right: -2%;
    bottom: -27%;
    z-index: -1;
  }
  
  .page-banner-section .shape-4 {
    position: absolute;
    right: -26%;
    bottom: -81%;
    z-index: -1;
  }
  
  @media only screen and (max-width: 1199px) {
    .page-banner-section {
      min-height: 520px;
    }
    .page-banner-section .shape-1 {
      left: -70%;
      top: -47%;
    }
    .page-banner-section .shape-2 {
      left: -30%;
      top: -55%;
    }
    .page-banner-section .shape-3 {
      right: -15%;
      bottom: -45%;
    }
    .page-banner-section .shape-4 {
      right: -50%;
      bottom: -130%;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .page-banner-section {
      min-height: 450px;
    }
    .page-banner-section .shape-1 {
      left: -95%;
      top: -58%;
    }
    .page-banner-section .shape-2 {
      left: -41%;
      top: -79%;
    }
    .page-banner-section .shape-3 {
      right: -25%;
      bottom: -60%;
    }
    .page-banner-section .shape-4 {
      right: -90%;
      bottom: -125%;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .page-banner-section {
      min-height: 350px;
    }
    .page-banner-section .shape-1 {
      display: none;
    }
    .page-banner-section .shape-2 {
      display: none;
    }
    .page-banner-section .shape-3 {
      display: none;
    }
    .page-banner-section .shape-4 {
      display: none;
    }
  }
  
  .page-banner .title {
    font-size: 60px;
    line-height: 1.25;
    /* font-family: "Barlow", sans-serif; */
    display: inline-block;
    font-weight: 600;
    color: #ffffff;
    position: relative;
    z-index: 1;
  }
  
  @media only screen and (max-width: 1199px) {
    .page-banner .title {
      font-size: 48px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .page-banner .title {
      font-size: 30px;
    }
  }
  
  .page-banner .breadcrumb {
    margin-bottom: 0;
    margin-top: 10px;
  }
  
  .page-banner .breadcrumb .breadcrumb-item {
    color: #ffffff;
    font-size: 20px;
  }
  .page-banner .breadcrumb .breadcrumb-item a {
    text-decoration: none;
    color: var(--color-primary);
  }
  
  @media only screen and (max-width: 991px), only screen and (max-width: 767px) {
    .page-banner .breadcrumb .breadcrumb-item {
      font-size: 16px;
    }
  }
  
  .page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item {
    padding-left: 8px;
  }
  
  .page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: "/";
    font-family: flaticon !important;
    font-size: 20px;
    padding-right: 5px;
    color: #ffffff;
  }