
.harsafe-servicess-section {
    background: #f8f8f8;
}
.right_choice_inner > div {
    margin-bottom: 20px;
}
.right_choice_inner .choice-item {
    display: inline-block;
    border: 1px solid #ededed;
    transition: .3s ease-out;
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 20px 15px 10px;
    height: 100%;
}
.counter-item {
    background: #fff;
    margin-bottom: 30px;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
}
.right_choice_inner.best_choice_inner .choice-item .modalBoximg {
    margin: 0 auto 10px;
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 50%;
}
.right_choice_inner.best_choice_inner .choice-item .modalBoximg img {
    margin: auto;
    height: 60px;
    width: 60px;
    /* object-fit: cover;
    filter: invert(31%) sepia(50%) saturate(6853%) hue-rotate(10deg) brightness(101%) contrast(94%) !important; */
}
.right_choice_inner .choice-item h4 {
    font-weight: 700;
    font-size: 20px;
    color: var(--color-primary);
    position: relative;
    transition: all .3s ease;
}

.right_choice_inner .choice-item p {
    color: #333;
    transition: .3s ease-out;
    margin: 0;
}
.right_choice_inner .choice-item:hover {
    z-index: 1;
}
.counter-item:hover {
    box-shadow: 0 0 25px rgba(0,0,0,.1);
}
.right_choice_inner .choice-item:hover h4 {
    color: var(--color-primary);
}
.right_choice_inner.best_choice_inner .choice-item:hover .modalBoximg {
    background: #fbfbfb;
    border: 1px solid #f5d4dd;
}
