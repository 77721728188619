.service-area {
  padding-bottom: 100px;
  position: relative;
  /* background-image: url('../images/schooll-bg.png'); */
  background-position: bottom;
  padding-top: 100px;
  overflow: hidden;
}
.service-tab.nav-pills .nav-link.active,
.service-tab.nav-pills .show > .nav-link {
  border-radius: 30px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-image: linear-gradient(90deg,#ff00b1 0%, var(--color-primary) 100%) !important;
  background-image: -webkit-linear-gradient(90deg,#ff00b1 0%, var(--color-primary) 100%);
  background-image: -ms-linear-gradient(90deg,#ff00b1 0%, var(--color-primary) 100%);
  background-color: #fff;
  color: #fff;
  padding: 10px 15px;
}
.service-tab li.nav-item {
  border: 1px solid #eee;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  /* transition: 0.4s all; */
}
.service-tab.nav-pills .nav-link {
  border-radius: 0;
  padding: 12px;
  color: #212121;
  font-size: 20px;
  font-weight: 600;
  /* transition: 0.4s all; */
}
.coman-feature {
  margin-bottom: 30px;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 38px 0px rgba(6, 16, 35, 0.11);
  padding: 20px;
}
.coman-feature .icon {
  width: 20%;
  float: left;
  margin-right: 15px;
  -webkit-animation: sp3 3s linear infinite;
  animation: sp3 3s linear infinite;
}
@keyframes sp3 {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 7px);
  }
  to {
    transform: translate(0, -0px);
  }
}
.coman-feature .icon img {
  width: 63px;
}

.coman-feature .content {
  width: 75%;
  display: inline-block;
}
.coman-feature .content h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}
.coman-feature .content p {
  font-size: 16px;
  margin: 0;
}
/* .bg-f{
    background-image: url('../images/bg-1.png');
} */

.tab-toggle-btn {
  display: none;
}
.nav-pills.active {
  display: block;
}
