
  
  .harsafe-service-section-07 .section-title {
    max-width: 810px;
    margin: 0 auto;
  }
  
  .harsafe-service-section-07 .experience-wrap {
    position: relative;
  }
  
  .harsafe-service-section-07 .experience-wrap .shape-1 {
    position: absolute;
    left: 102px;
    top: -28px;
    -webkit-animation: round-01 5s linear infinite;
            animation: round-01 5s linear infinite;
    z-index: -1;
  }
  
  .harsafe-service-section-07 .experience-wrap .experience {
    width: 370px;
    height: 370px;
    background: linear-gradient(90deg,#ff00b1, var(--color-primary));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    padding: 130px 90px;
    position: absolute;
    left: 150px;
    top: 20px;
  }
  
  .harsafe-service-section-07 .experience-wrap .experience .number {
    font-size: 170px;
    line-height: 54px;
    font-weight: 600;
    color: #ffffff;
  }
  
  .harsafe-service-section-07 .experience-wrap .experience span {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    color: #ffffff;
    margin-top: 60px;
    padding-left: 10px;
  }
  
  .harsafe-service-section-07 .service-content {
    padding-right: 225px;
  }
  
  .harsafe-service-section-07 .service-content .text {
    font-size: 18px;
    line-height: 30px;
  }
  
  .harsafe-service-section-07 .service-content .service-list {
    padding-top: 15px;
  }
  
  .harsafe-service-section-07 .service-content .service-list ul li {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-top: 20px;
  }
  
  .harsafe-service-section-07 .service-content .service-list ul li .service-icon {
    color: #11ddf5;
    margin-right: 8px;
  }
  
  @media only screen and (max-width: 1399px) {
    .harsafe-service-section-07 .service-content {
      padding-right: 0px;
    }
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-service-section-07 .experience-wrap .shape-1 {
      left: 0px;
      top: -28px;
    }
    .harsafe-service-section-07 .experience-wrap .experience {
      padding: 130px 90px;
      left: 45px;
      top: 15px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-service-section-07 {
      padding-bottom: 100px;
    }
    .harsafe-service-section-07 .experience-wrap .shape-1 {
      left: 115px;
      transform: translateX(-50%);
    }
    .harsafe-service-section-07 .experience-wrap .experience {
      left: 0;
      position: relative;
      margin: 0 auto;
    }
    .harsafe-service-section-07 .service-content {
      margin-top: 110px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .harsafe-service-section-07 {
      padding-bottom: 80px;
    }
    .harsafe-service-section-07 .experience-wrap .shape-1 {
      left: 30px;
      top: -50px;
    }
    .harsafe-service-section-07 .experience-wrap .experience {
      width: 325px;
      height: 325px;
      padding: 105px 70px;
    }
    .harsafe-service-section-07 .experience-wrap .experience .number {
      font-size: 150px;
    }
    .harsafe-service-section-07 .experience-wrap .experience span {
      font-size: 16px;
      margin-top: 50px;
    }
    .harsafe-service-section-07 .service-content {
      margin-top: 120px;
    }
  }
  
  @media only screen and (max-width: 449px) {
    .harsafe-service-section-07 .experience-wrap .shape-1 {
      width: 350px;
      left: 0;
      top: -25px;
    }
    .harsafe-service-section-07 .experience-wrap .experience {
      width: 290px;
      height: 290px;
      padding: 80px 65px;
      left: 0;
      top: 0;
    }
    .harsafe-service-section-07 .experience-wrap .experience .number {
      font-size: 140px;
    }
    .harsafe-service-section-07 .experience-wrap .experience span {
      font-size: 16px;
    }
    .harsafe-service-section-07 .service-content {
      margin-top: 85px;
    }
  }