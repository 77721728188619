
.harsafe-about-section-07 .about-img-wrap {
    display: flex;
    position: relative;
    z-index: 1;
  }
  
  .harsafe-about-section-07 .about-img-wrap .shape-1 {
    position: absolute;
    right: 55px;
    bottom: -30px;
    z-index: -1;
    filter: invert(100%) sepia(173%) saturate(6877%) hue-rotate(0deg) brightness(100%) contrast(150%) !important;
  }
  
  .harsafe-about-section-07 .about-img-wrap .about-img {
    overflow: hidden;
  }
  
  .harsafe-about-section-07 .about-img-wrap .about-img img {
    border-radius: 10px;
  }
  
  .harsafe-about-section-07 .about-img-wrap .about-img:first-child {
    padding-right: 15px;
  }
  
  .harsafe-about-section-07 .about-img-wrap .about-img:last-child {
    padding-left: 15px;
    margin-top: 75px;
  }
  
  .harsafe-about-section-07 .about-content-wrap {
    padding-left: 20px;
  }
  
  .harsafe-about-section-07 .about-content-wrap .text {
    font-size: 16px;
    line-height: 30px;
    /* margin-top: 25px; */
    margin-top: 0px;
  }
  .about-content-wrap .section-title .title {
    font-size: 34px;
    line-height: 46px;
  }
  .harsafe-about-section-07 .about-content-wrap .about-list-03 {
    border-top: 1px solid #e1e1e1;
    margin-top: 40px;
    padding-top: 15px;
  }
  
  .harsafe-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 {
    padding-right: 40px;
    margin-top: 25px;
  }
  
  .harsafe-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 .title {
    font-size: 18px;
    line-height: 30px;
    font-weight: 700;
    color: #231e32;
  }
  
  .harsafe-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 p {
    font-size: 16px;
    line-height: 30px;
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-about-section-07 .about-img-wrap .shape-1 {
      right: 10px;
      bottom: -30px;
      width: 370px;
    }
    .harsafe-about-section-07 .about-content-wrap {
      padding-left: 40px;
    }
    .harsafe-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 {
      padding-right: 10px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-about-section-07 .about-img-wrap {
      justify-content: center;
    }
    .harsafe-about-section-07 .about-img-wrap .shape-1 {
      right: 75px;
      width: inherit;
    }
    .harsafe-about-section-07 .about-content-wrap {
      padding-left: 0px;
      padding-top: 60px;
    }
    .harsafe-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 {
      padding-right: 40px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .harsafe-about-section-07 .about-img-wrap .shape-1 {
      right: 40px;
      width: 370px;
    }
    .harsafe-about-section-07 .about-content-wrap .about-list-03 .about-list-item-03 {
      padding-right: 0px;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .harsafe-about-section-07 .about-img-wrap .shape-1 {
      display: none;
    }
    .harsafe-about-section-07 .about-img-wrap .about-img {
      width: 100%;
      height: 235px;
    }
    .harsafe-about-section-07 .about-img-wrap .about-img img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .harsafe-about-section-07 .about-content-wrap {
      padding-top: 30px;
    }
  }
  


/* Tab CSS  */

.harsafe-about-section-07 .nav-pills-custom .nav-link {
    color: #686868;
    background: #fff;
    position: relative;
    cursor: pointer;
    font-weight: 500;
  }
  
  .harsafe-about-section-07 .nav-pills-custom .nav-link.active {
    color: #fff;
    background: var(--color-primary);
    font-weight: 700;
  }
  
  
  /* Add indicator arrow for the active tab */
  @media (min-width: 768px) {
   .harsafe-about-section-07 .nav-pills-custom .nav-link::before {
        content: '';
        display: block;
        border-top: 8px solid transparent;
        border-left: 10px solid var(--color-primary);
        border-bottom: 8px solid transparent;
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        opacity: 0;
      }
     .harsafe-about-section-07 .nav-pills-custom .nav-link.active::before {
      opacity: 1;
  }
  }
  
  

