

.harsafe-blog-section {
    padding-bottom: 70px;
  }
  
  .harsafe-blog-wrap {
    padding-top: 20px;
  }
  
  .single-blog {
    margin-top: 30px;
  }
  
  .single-blog .blog-image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .single-blog .blog-image a {
    display: block;
  }
  
  .single-blog .blog-image a img {
    transition: all 0.3s linear;
  }
  
  .single-blog .blog-image .top-meta {
    position: absolute;
    top: 35px;
    left: 30px;
  }
  
  .single-blog .blog-image .top-meta .date {
    width: 65px;
    height: 65px;
    line-height: 65px;
    text-align: center;
    background: linear-gradient(-180deg, #ff00b1 0%, #ff0000 100%);
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
    font-family: "Barlow", sans-serif;
    display: inline-block;
    text-align: center;
    line-height: 12px;
    padding: 10px;
  }
  
  .single-blog .blog-image .top-meta .date span {
    font-size: 28px;
    line-height: 18px;
    font-weight: 500;
    line-height: 30px;
    display: block;
  }
  
  .single-blog .blog-content {
    padding: 25px 30px 40px;
    background: #ffffff;
    width: 90%;
    position: relative;
    left: 50%;
    bottom: 10px;
    transform: translateX(-48%);
    margin-top: -40px;
  }
  
  @media only screen and (max-width: 1199px) {
    .single-blog .blog-content {
      padding: 25px 20px 40px;
      width: 95%;
    }
  }
  
  .single-blog .blog-content .blog-meta span {
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
    margin-right: 15px;
  }
  .single-blog .blog-content .blog-meta span a{
    color: #999999;
    text-decoration: none;
  }
  
  @media only screen and (max-width: 575px) {
    .single-blog .blog-content .blog-meta span {
      margin-right: 12px;
    }
  }
  
  .single-blog .blog-content .blog-meta span i {
    color: var(--color-primary);
    margin-right: 5px;
  }
  
  .single-blog .blog-content .title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    padding-right: 15px;
    margin-top: 15px;
  }
  .single-blog .blog-content .title a {
    color: #0e0e0e;
    text-decoration: none;
  }
  
  @media only screen and (max-width: 1199px) {
    .single-blog .blog-content .title {
      padding-right: 0;
    }
  }
  
  .single-blog .blog-btn .blog-btn-link {
    font-size: 12px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 600;
    color: var(--color-primary);
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 15px;
    transition: all 0.3s linear;
    text-decoration: none;
  }
  
  .single-blog .blog-btn .blog-btn-link i {
    transition: all 0.3s linear;
  }
  
  .single-blog .blog-btn .blog-btn-link:hover i {
    margin-left: 5px;
  }
  
  .single-blog:hover .blog-image a img {
    transform: scale(1.1);
  }
  
  .harsafe-blog-grid-wrap {
    margin-top: -30px;
  }
  



/*--Blog Sidebar--*/

.blog-sidebar {
    /* margin-top: 40px; */
    margin-left: 30px;
    position: sticky;
    top: 90px;
  }
  
  @media only screen and (max-width: 1199px) {
    .blog-sidebar {
      margin-left: 15px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .blog-sidebar {
      margin-left: 0px;
    }
  }
  
  .blog-sidebar .sidebar-widget-1 {
    margin-top: 0;
  }
  
  .sidebar-widget {
    margin-top: 45px;
  }
  
  .sidebar-widget .search-form {
    position: relative;
  }
  
  .sidebar-widget .search-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
    width: 100%;
    height: 50px;
    margin-bottom: 0;
    border: 0;
    padding-right: 35px;
    background: #f2f4f7;
    color: #415674;
    font-weight: 700;
    outline: none;
    overflow: hidden;
  }
  
  .sidebar-widget .search-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-webkit-input-placeholder {
    opacity: 0.95;
    font-size: 14px;
    /* font-family: "Barlow", sans-serif; */
    color: #898c94;
    font-weight: 400;
  }
  
  .sidebar-widget .search-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-moz-placeholder {
    opacity: 0.95;
    font-size: 14px;
    /* font-family: "Barlow", sans-serif; */
    color: #898c94;
    font-weight: 400;
  }
  
  .sidebar-widget .search-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"])::-moz-placeholder {
    opacity: 0.95;
    font-size: 14px;
    /* font-family: "Barlow", sans-serif; */
    color: #898c94;
    font-weight: 400;
  }
  
  .sidebar-widget .search-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]):-ms-input-placeholder {
    opacity: 0.95;
    font-size: 14px;
    /* font-family: "Barlow", sans-serif; */
    color: #898c94;
    font-weight: 400;
  }
  
  .sidebar-widget .search-form button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 50px;
    height: 50px;
    background: transparent;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
    color: #111111;
    transition: all 0.3s linear;
  }
  
  .sidebar-widget .search-form button:hover {
    background: var(--color-primary);
    color: #ffffff;
  }
  
  .sidebar-widget .widget-title {
    margin-bottom: 25px;
  }
  
  .sidebar-widget .widget-title .title {
    font-size: 20px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 700;
    color: #0e0e0e;
    display: inline-block;
  }
  
  .sidebar-widget .recent-posts ul li {
    border-top: 1px solid #e5e5e5;
    padding-top: 30px;
    margin-top: 30px;
  }
  
  .sidebar-widget .recent-posts ul {
    list-style: none;
    padding: 0;
  }
  .sidebar-widget .recent-posts ul li:first-child {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
  }
  
  .sidebar-widget .recent-posts ul li .post-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #0e0e0e;
  }
  
  .sidebar-widget .recent-posts ul li .post-link .post-thumb {
    margin-right: 20px;
  }
  
  .sidebar-widget .recent-posts ul li .post-link .post-thumb img {
    border-radius: 50%;
    image-rendering: crisp-edges;
  }
  
  .sidebar-widget .recent-posts ul li .post-link .post-text {
    flex: 1;
  }
  
  .sidebar-widget .recent-posts ul li .post-link .post-text .title {
    font-size: 16px;
    line-height: 18px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 600;
    transition: all 0.3s linear;
  }
  
  .sidebar-widget .recent-posts ul li .post-link .post-text .post-meta {
    font-size: 12px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
    line-height: 24px;
    color: #838383;
    display: inline-block;
    margin-top: 5px;
  }
  
  .sidebar-widget .recent-posts ul li .post-link .post-text .post-meta i {
    color: var(--color-primary);
    margin-right: 5px;
  }
  
  .sidebar-widget .recent-posts ul li .post-link:hover .post-text .title {
    color: var(--color-primary);
  }
  
  .sidebar-widget .widget-banner {
background: url(../../Img/blog/sidebar-img.jpg);  
}
  .sidebar-widget .widget-banner {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
    padding: 60px 40px 90px;
  }
  
  .sidebar-widget .widget-banner .banner-content {
    max-width: 160px;
  }
  
  .sidebar-widget .widget-banner .banner-content .title {
    font-size: 36px;
    line-height: 1;
    font-weight: 600;
    color: #ffffff;
  }
  
  .sidebar-widget .widget-banner .banner-content .btn {
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    padding: 0 40px;
    margin-top: 30px;
  }
  
  .sidebar-widget .category .cate-item {
    border-top: 1px solid #ebebeb;
    padding-top: 10px;
    margin-top: 15px;
  }
  
  .sidebar-widget ul.category {
  list-style: none;
  padding: 0;
  }
  .sidebar-widget .category .cate-item:first-child {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
  }
  
  .sidebar-widget .category .cate-item a {
    display: flex;
    font-size: 15px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 500;
    color: #29303c;
    transition: all 0.3s linear;
    text-decoration: none;
  }
  
  .sidebar-widget .category .cate-item a i {
    font-size: 10px;
    line-height: 24px;
    color: var(--color-primary);
    margin-right: 5px;
  }
  
  .sidebar-widget .category .cate-item a .post-count {
    margin-left: auto;
    align-items: flex-start;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background: #e4f2f8;
    font-size: 14px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
    color: var(--color-primary);
    transition: all 0.3s linear;
  }
  
  .sidebar-widget .category .cate-item a:hover {
    color: var(--color-primary);
  }
  
  .sidebar-widget .category .cate-item a:hover .post-count {
    background: var(--color-primary);
    color: #ffffff;
  }
  
  .sidebar-widget .sidebar-tag li {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 4px;
  }
  
  .sidebar-widget .sidebar-tag li a {
    display: inline-block;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 14px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    background: #f9f9f9;
    transition: all 0.3s linear;
    text-decoration: none;
  }
  
  .sidebar-widget .sidebar-tag li a:hover {
    background-color: var(--color-primary);
    color: #ffffff;
  }
  
  /*--Page Pagination--*/
  .harsafe-pagination {
    margin-top: 60px;
  }
  
  .harsafe-pagination .pagination li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
    transition: all 0.3s linear;
    display: block;
    color: #0e0e0e;
    text-decoration: none;
  }
  
  .harsafe-pagination .pagination li a.active {
    background-color: var(--color-primary);
    color: #ffffff;
  }
  




  
/*--
/*  18 - Blog Details CSS
/*----------------------------------------*/
.blog-details-section .blog-details-wrap {
  margin-top: -40px;
}

.blog-details-post {
  padding-right: 30px;
}

.blog-details-post .single-blog-post {
  margin-top: 40px;
}

.blog-details-post .single-blog-post.single-blog .blog-content {
  padding: 30px 0px 0px;
  width: 100%;
  left: 0;
  bottom: 0px;
  transform: translateX(0);
  margin-top: 0px;
}

.blog-details-post .single-blog-post.single-blog .blog-content .blog-meta .tag {
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  color: var(--color-primary);
}

.blog-details-post .single-blog-post.single-blog .blog-content .title {
  font-size: 36px;
  line-height: 30px;
}

.blog-details-post .single-blog-post.single-blog .blog-content p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 20px;
}

.blog-details-post .blog-details-content .blog-quote {
  background-color: none;
  position: relative;
  margin-left: 50px;
  margin-top: 40px;
}

.blog-details-post .blog-details-content .blog-quote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: var(--color-primary);
  width: 6px;
  height: 105px;
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .blog-quote::after {
    display: none;
  }
}

.blog-details-post .blog-details-content .blog-quote .blockquote {
  padding-right: 125px;
  padding-left: 25px;
  margin-bottom: 0;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .blog-details-post .blog-details-content .blog-quote .blockquote {
    padding-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .blog-quote .blockquote {
    margin-left: 0;
  }
}

.blog-details-post .blog-details-content .blog-quote .blockquote:not(:first-child) {
  margin-top: 25px;
}

.blog-details-post .blog-details-content .blog-quote .blockquote:not(:last-child) {
  margin-bottom: 25px;
}

.blog-details-post .blog-details-content .blog-quote .blockquote p {
  display: inline;
  font-size: 18px;
  /* font-family: "Barlow", sans-serif; */
  color: #333333;
  line-height: 32px;
  font-weight: 600;
  margin-top: 0;
}

.blog-details-post .blog-details-content .blog-details-text p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 50px;
}

.blog-details-post .blog-details-content .blog-details-tag-share {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 65px;
  padding-top: 20px;
  border-top: 1px solid #e3e3e5;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-tag {
  padding-top: 20px;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-tag .sidebar-widget {
  margin-top: 0;
  display: flex;
  align-items: center;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-tag .sidebar-widget .label {
  font-size: 14px;
  line-height: 30px;
  /* font-family: "Barlow", sans-serif; */
  font-weight: 700;
  color: #333333;
  margin-right: 10px;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-tag .sidebar-widget ul.sidebar-tag {
  margin: 0;
}
.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-tag .sidebar-widget .sidebar-tag li {
  margin-bottom: 0px;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-share {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-share li {
  display: inline-block;
  margin-right: 10px;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-share li:last-child {
  margin-right: 0;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-share li a {
  display: inline-block;
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ededed;
  text-align: center;
  color: var(--color-primary);
  border-radius: 50%;
}

.blog-details-post .blog-details-content .blog-details-tag-share .blog-details-share li a:hover {
  opacity: 0.9;
}

.blog-details-post .blog-details-content .harsafe-post-pagination {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.16);
  padding: 10px 30px;
  margin-top: 80px;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .blog-details-post .blog-details-content .harsafe-post-pagination {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .harsafe-post-pagination {
    padding: 0 10px;
  }
}

.blog-details-post .blog-details-content .harsafe-post-pagination::before {
  position: absolute;
  content: '';
  width: 1px;
  background-color: #e1e1e1;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-post .blog-details-content .harsafe-post-pagination::before {
    width: auto;
    height: 1px;
    left: 30px;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
  }
}

.blog-details-post .blog-details-content .harsafe-post-pagination .previous-post,
.blog-details-post .blog-details-content .harsafe-post-pagination .next-post {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .blog-details-post .blog-details-content .harsafe-post-pagination .previous-post,
  .blog-details-post .blog-details-content .harsafe-post-pagination .next-post {
    width: 100%;
  }
}

.blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post {
  display: flex;
  align-items: center;
  padding: 30px 0;
}

@media only screen and (max-width: 1199px) {
  .blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post {
    padding: 20px 0;
  }
}

.blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-thumb {
  flex-shrink: 0;
}
.blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-thumb a {
  text-decoration: none;
}

.blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-thumb a img {
  border-radius: 10px;
  -o-object-position: center;
     object-position: center;
  -o-object-fit: cover;
     object-fit: cover;
}

.blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-thumb a i {
  font-size: 14px;
  color: var(--color-primary);
  padding: 0 10px;
}

.blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-content {
  flex-grow: 1;
  padding: 0 20px;
}

@media only screen and (max-width: 1199px) {
  .blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-content {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-content {
    padding: 0 13px;
  }
}

.blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-content .title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

  .blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-content .title a {
  color: #0e0e0e;
  text-decoration: none;
}

@media only screen and (max-width: 1199px) {
  .blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-content .title {
    font-size: 15px;
  }
}

.blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-content .date {
  font-size: 12px;
  line-height: 22px;
  color: #838383;
}

.blog-details-post .blog-details-content .harsafe-post-pagination .blog-pagination-post .post-content .date i {
  color: var(--color-primary);
  margin-right: 6px;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form {
  margin-top: 50px;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-title {
  font-size: 22px;
  line-height: 30px;
  color: #0e0e0e;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form p {
  font-size: 14px;
  line-height: 30px;
  color: #686f7a;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap {
  padding-top: 10px;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form {
  margin-top: 20px;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control {
  border: 1px solid transparent;
  background: #f6f7f9;
  border-radius: 0;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  /* font-family: "Barlow", sans-serif; */
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control:-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  /* font-family: "Barlow", sans-serif; */
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control::-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  /* font-family: "Barlow", sans-serif; */
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  /* font-family: "Barlow", sans-serif; */
  color: #9d9d9d;
  font-weight: 400;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form .form-control:focus {
  outline: none;
  border-color: var(--color-primary);
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form textarea.form-control {
  height: 135px;
  padding-top: 15px;
  font-size: 13px;
  color: #415674;
  font-weight: 600;
  padding: 10px 25px;
  resize: none;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .single-form textarea.form-control:focus {
  outline: none;
  box-shadow: none;
}

.blog-details-post .blog-details-content .comment-wrap .comment-form .comment-form-wrap .form-btn .btn {
  height: 40px;
  line-height: 38px;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  margin-top: 25px;
  font-size: 16px;
  padding: 0 40px;
}



/*--
-
-
-
-
-
-
-
Blog Page End
-
-
-
-
-
-

--*/