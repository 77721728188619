@import "~slick-carousel/slick/slick.css";
/* @import "~slick-carousel/slick/slick-theme.css"; */



.screenshot-slider {
    width: 80%;
    margin: auto;
  }
  
  .screenshot-slider img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .slider.ssslider {
    position: relative;
    max-width: 800px;
    margin: auto;
    overflow: hidden;
    border: 2px solid #ddd;
    border-radius: 10px;
  }
  
  .slider-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .slider-image {
    min-width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .slider-image.active {
    opacity: 1;
  }
  
  .slider-button {
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
  }
  
  .slider-button.right {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  .slider-button:hover {
    background-color: rgba(0,0,0,0.8);
  }
  
  .slider-button.left {
    left: 0;
  }

