
.bg-cover {
  background: url(../../../Img/bg/testi-bg4.jpg) !important;
}
.harsafe-testimonial-section-02 {
    /* background: #ff5538; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  
  .harsafe-testimonial-section-02 .testimonial-content-wrap-02 {
    margin-top: 60px;
  }
  
  .harsafe-testimonial-section-02 .single-testimonial-02 {
    background: #ffffff;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    width: 570px;
    margin: 0 40px;
    position: relative;
  }
  
  .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-thumb {
    position: absolute;
    left: -7%;
    top: 50%;
    transform: translateY(-65%);
    z-index: 3;
    width: 135px;
    height: 135px;
  }
  
  .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-thumb img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 0;
  }
  
  .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-content {
    padding: 40px 130px;
    padding-right: 40px;
  }
  .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-content img {
    filter: invert(100%) sepia(173%) saturate(6877%) hue-rotate(0deg) brightness(100%) contrast(150%) !important;
  }
  
  .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-content p {
    font-size: 20px;
    line-height: 32px;
    color: #4c4d56;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  
  .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-content .name {
    font-size: 16px;
    line-height: 20px;
    color: #0e0e0e;
    font-weight: 600;
  }
  
  .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-content .designation {
    font-size: 14px;
    line-height: 20px;
    color: #47484a;
    font-weight: 500;
  }
  
  .testimonial-02-active .swiper-pagination {
    position: relative;
    margin-top: 50px;
  }
  
  .testimonial-02-active .swiper-pagination .swiper-pagination-bullet {
    margin: 0 7px;
  }
  
  .testimonial-02-active .swiper-pagination .swiper-pagination-bullet::after {
    border: 1px solid #ffffff;
  }
  
  .testimonial-02-active .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet {
    background-color: var(--color-primary);
  }
  .testimonial-02-active .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--color-primary);
  }
  
  @media only screen and (max-width: 1399px) {
    .harsafe-testimonial-section-02 .single-testimonial-02 {
      margin: 0 auto;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .harsafe-testimonial-section-02 .single-testimonial-02 {
      width: 100%;
      text-align: center;
    }
    .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-thumb {
      position: relative;
      text-align: center;
      left: 0;
      top: 0;
      padding-top: 40px;
      transform: translateY(0%);
    }
    .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-content {
      padding: 40px 45px;
    }
  }


  .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-thumb::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: var(--color-primary);
    width: 135px;
    height: 135px;
    border-radius: 10px;
    transform: translate(5px, 5px);
    z-index: -1;
  }
  
  @media only screen and (max-width: 767px) {
    .harsafe-testimonial-section-02 .single-testimonial-02 .testimonial-thumb::before {
      left: 50%;
      top: 50%;
      transform: translate(-45%, -31%);
    }
  }
  
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1
  }