
.hersafe-about-section-03 {
    overflow: hidden;
    padding-bottom: 180px;
  }
  
  .hersafe-about-section-03 .about-content-03 {
    padding-right: 145px;
  }
  
  .hersafe-about-section-03 .about-content-03 .text {
    font-size: 16px;
    line-height: 30px;
    margin-top: 20px;
  }
  
  .hersafe-about-section-03 .about-content-03 .about-quote {
    background: var(--color-primary-20);
    padding: 40px 30px;
    padding-right: 85px;
    border-radius: 5px;
    margin-top: 30px;
  }
  
  .hersafe-about-section-03 .about-content-03 .about-quote .blockquote {
    padding-left: 60px;
    margin-bottom: 0;
    position: relative;
  }
  
  .hersafe-about-section-03 .about-content-03 .about-quote .blockquote::before {
    content: "\f115";
    font-family: flaticon !important;
    font-size: 40px;
    line-height: 1;
    margin-right: 5px;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--color-primary);
  }
  
  .hersafe-about-section-03 .about-content-03 .about-quote .blockquote p {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: #0e0e0e;
  }
  
  .hersafe-about-section-03 .about-content-03 .about-list-02 {
    padding-top: 20px;
    padding-right: 25px;
  }
  
  .hersafe-about-section-03 .about-content-03 .about-list-02 .about-list-item-02 {
    margin-top: 20px;
  }
  
  .hersafe-about-section-03
    .about-content-03
    .about-list-02
    .about-list-item-02
    .title {
    font-size: 18px;
    line-height: 30px;
    color: #231e32;
  }
  
  .hersafe-about-section-03
    .about-content-03
    .about-list-02
    .about-list-item-02
    .title
    i {
    color: var(--color-primary);
    margin-right: 7px;
  }
  
  .hersafe-about-section-03
    .about-content-03
    .about-list-02
    .about-list-item-02
    P {
    font-size: 16px;
    line-height: 26px;
    margin-top: 10px;
  }
  
  .hersafe-about-section-03 .about-img-warp-3 {
    position: relative;
    z-index: 1;
  }
  
  .hersafe-about-section-03 .about-img-warp-3 .shape-1 {
    width: 520px;
    height: 490px;
    background: var(--color-primary);
    position: absolute;
    left: 85px;
    bottom: -30px;
    z-index: -1;
  }
  
  .hersafe-about-section-03 .about-img-warp-3 .about-img img {
    border-radius: 10px;
  }
  
  .hersafe-about-section-03 .about-img-warp-3 .about-img-big {
    text-align: right;
  }
  
  .hersafe-about-section-03 .about-img-warp-3 .about-img-sm {
    position: absolute;
    left: 0px;
    bottom: -65px;
  }
  
  @media only screen and (max-width: 1399px) {
    .hersafe-about-section-03 .about-content-03 {
      padding-right: 60px;
    }
  }
  
  @media only screen and (max-width: 1199px) {
    .hersafe-about-section-03 {
      padding-bottom: 100px;
    }
    .hersafe-about-section-03 .about-content-03 {
      padding-right: 40px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .hersafe-about-section-03 {
      padding-bottom: 180px;
    }
    .hersafe-about-section-03 .about-content-03 {
      padding-right: 140px;
      margin-bottom: 60px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .hersafe-about-section-03 {
      padding-bottom: 150px;
    }
    .hersafe-about-section-03 .about-content-03 {
      padding-right: 0px;
    }
    .hersafe-about-section-03 .about-img-warp-3 .shape-1 {
      left: 25px;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .hersafe-about-section-03 {
      padding-bottom: 70px;
    }
    .hersafe-about-section-03 .about-content-03 .about-quote {
      padding-right: 35px;
    }
    .hersafe-about-section-03 .about-img-warp-3 .shape-1 {
      display: none;
    }
    .hersafe-about-section-03 .about-img-warp-3 .about-img-big {
      text-align: center;
    }
    .hersafe-about-section-03 .about-img-warp-3 .about-img-sm {
      display: none;
    }
  }