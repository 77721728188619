.harsafe-counter-section {
    background: var(--color-primary-20);
    padding-top: 60px;
    padding-bottom: 80px;
  }
  
  .harsafe-counter-section .counter-wrap {
    margin-top: -30px;
  }
  
  .single-counter {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  
  .single-counter .counter-img {
    height: 95px;
    width: 95px;
    line-height: 95px;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
  }
  .single-counter .counter-img img{
    filter: invert(100%) sepia(173%) saturate(6877%) hue-rotate(0deg) brightness(100%) contrast(150%) !important;
  }
  
  .single-counter .counter-content {
    margin-left: 20px;
  }
  
  .single-counter .counter-content span {
    font-size: 40px;
    line-height: 26px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 700;
    color: var(--color-primary);
  }
  
  .single-counter .counter-content p {
    font-size: 12px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    color: #4c4d56;
    margin: 0;
  }