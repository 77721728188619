
.service-bg {
    background: url(../../Img/bg/service-bg.jpg) !important;
}



.harsafe-service-section-02 {
    background: #f8f8f8;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  
  .harsafe-service-section-02 .service-item-02 {
    background: #ffffff;
    box-shadow: 0px 43px 100px 0px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    padding: 40px 45px 45px;
    margin-top: 30px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s linear;
  }
  
  .harsafe-service-section-02 .service-item-02 .service-img {
    position: relative;
    z-index: 1;
  }
  
  .harsafe-service-section-02 .service-item-02 .service-img img {
    height: 55px;
    position: relative;
    left: 15px;
  }
  
  .harsafe-service-section-02 .service-item-02 .service-img::before {
    content: "";
    position: absolute;
    left: 0;
    top: -18%;
    background: var(--color-primary);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    transition: all 0.3s linear;
    z-index: -1;
  }
  
  .harsafe-service-section-02 .service-item-02 .next-icon {
    position: absolute;
    right: 15%;
    top: 15%;
  }
  
  .harsafe-service-section-02 .service-item-02.service-2, .harsafe-service-section-02 .service-item-02.service-4 {
    margin-top: 55px;
  }
  
  .harsafe-service-section-02 .service-item-02 .service-content {
    position: relative;
    z-index: 5;
  }
  
  .harsafe-service-section-02 .service-item-02 .service-content .title {
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;
  }
  
    .harsafe-service-section-02 .service-item-02 .service-content .title a {
    color: #0e0e0e;
    text-decoration: none;
  }
  
  .harsafe-service-section-02 .service-item-02 .service-content p {
    margin-top: 15px;
    transition: all 0.3s linear;
  }
  
  .harsafe-service-section-02 .service-item-02:hover {
    transform: translateY(-5px) translate3d(0, -5px, 0);
  }
  .harsafe-service-section-02 .service-item-02:hover .service-content .title a {
    color: var(--color-primary);
  }
  
  .harsafe-service-section-02 .more-service-content {
    margin-top: 30px;
  }
  
  .harsafe-service-section-02 .more-service-content p {
    font-size: 16px;
    line-height: 30px;
  }
  
  .harsafe-service-section-02 .more-service-content p a {
    font-weight: 600;
    color: var(--color-primary);
  }
  
  .harsafe-service-section-02 .more-service-content p a i {
    position: relative;
    top: 1px;
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-service-section-02 .service-item-02.service-2, .harsafe-service-section-02 .service-item-02.service-4 {
      margin-top: 30px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .harsafe-service-section-02 .service-item-02 {
      padding: 40px 40px 45px;
    }
  }
  