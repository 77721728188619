
.harsafe-about-section-02 .about-02-left {
    padding: 0 35px;
  }
  
  .harsafe-about-section-02 .about-02-left .section-title .title {
    font-size: 42px;
    line-height: 60px;
  }
  
  .harsafe-about-section-02 .about-02-left .about-author {
    margin-top: 30px;
  }
  
  .harsafe-about-section-02 .about-02-left .about-author .name {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
  }
  
  .harsafe-about-section-02 .about-02-left .about-author .designation {
    font-size: 12px;
    line-height: 18px;
    color: #4c4d56;
  }
  
  .harsafe-about-section-02 .about-02-right {
    position: relative;
    padding-left: 112px;
  }
  
  .harsafe-about-section-02 .about-02-right::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #e1e1e1;
    width: 1px;
    height: 350px;
  }
  
  .harsafe-about-section-02 .about-02-right p {
    font-size: 16px;
    line-height: 30px;
    color: #4c4d56;
  }
  
  .about-list {
    margin-top: 40px;
  }
  
  .about-02-right .about-list ul {
    list-style: none;
  }
  .about-list ul li {
    font-size: 16px;
    line-height: 36px;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    color: #333333;
  }
  
  .about-list ul li i {
    font-size: 16px;
    color: var(--color-primary);
    margin-right: 10px;
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-about-section-02 .about-02-right {
      padding-left: 80px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-about-section-02 .about-02-left {
      padding: 0 0px;
    }
    .harsafe-about-section-02 .about-02-right {
      padding-left: 0px;
      margin-top: 50px;
    }
    .harsafe-about-section-02 .about-02-right::before {
      display: none;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .harsafe-about-section-02 .about-02-left .section-title .title {
      font-size: 36px;
      line-height: 52px;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .harsafe-about-section-02 .about-02-left .section-title .title {
      font-size: 30px;
      line-height: 48px;
    }
  }
  