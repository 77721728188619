/* Start Hero BG Slider */

.page-banner-section {
  float: none;
}

.homeSlider {
    padding: 60px 0;
  }
  
  .homeSlider .sliderBody {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .homeSlider .sliderBody .slider {
    width: 920px;
    padding: 0 10px;
  }
  
  .homeSlider .sliderBody .slider .homeMainSlider .swiper-slide .sliderItem {
    padding: 40px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    border-radius: 10px;
  }
  
  .homeSlider
    .sliderBody
    .slider
    .homeMainSlider
    .swiper-slide
    .sliderItem
    .subHeading {
    font-size: 16px;
    font-weight: 800;
    color: var(--color-primary);
    margin-bottom: 10px;
  }
  
  .homeSlider .sliderBody .slider .homeMainSlider .swiper-slide .sliderItem h2 {
    color: #fff;
    font-size: 27px;
    font-weight: 800;
    margin-bottom: 10px;
    line-height: 2;
  }
  
  .homeSlider .sliderBody .slider .homeMainSlider .swiper-slide .sliderItem p {
    color: #fff;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 25px;
  }
  
  .homeSlider
    .sliderBody
    .slider
    .homeMainSlider
    .swiper-slide
    .sliderItem
    .default-btn {
    font-size: 14px;
    font-weight: 600;
  }
  
  .default-btn {
    font-size: 16px;
    color: #ffffff;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: -ms-inline-grid;
    display: inline-grid;
    padding: 8px 30px;
    background-color: var(--color-primary);
    border-radius: 100px;
    border: 1px solid var(--color-primary);
    text-decoration: none;
  }
  
  .default-btn:hover {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background-color: #fff;
  }
  
  .homeMainSlider.owl-carousel .owl-dots {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 5px;
  }
  
  .homeMainSlider.owl-carousel .owl-dots .owl-dot.active span {
    background-color: #000;
    width: 30px;
  }
  
  .homeMainSlider.owl-carousel .owl-dots .owl-dot span {
    width: 20px;
    background: #949494;
    height: 5px;
    border-radius: 100px;
    margin: 0 5px;
  }
  
  .homeSlider .sliderBody .sliderRightPoster {
    width: calc(100% - 920px);
    padding: 0 10px;
  }
  
  .homeSlider .sliderBody .sliderRightPoster .sliderItem {
    padding: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 195px;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .homeSliderCar {
    overflow: hidden;
    position: relative;
  }
  .homeSliderCar,
  .homeSliderCar .container-fluid,
  .homeSliderCar > .container-fluid .row > .col-md-12 {
    padding: 0;
  }
  .homeSliderCar .sliderBody .slider {
    padding: 0;
    width: 100%;
  }
  .homeSliderCar .sliderBody .slider .homeMainSlider .swiper-slide {
      height: calc(100vh - 120px);
      max-height: 700px;
      /* height: auto; */
      border-radius: 0;
      padding: 100px;
      padding-top: 200px;
      background-size: cover !important;
      background-position: center;
      background-repeat: no-repeat;
    }
    .homeSliderCar .sliderBody .slider .homeMainSlider .swiper-slide:nth-child(1) {
        background: linear-gradient(325deg, rgba(0, 9, 14, 0) 0%, rgba(0, 9, 18, 1) 100%), url(../../Img/side-view-woman-working-media-field-with-computer-headphones.jpg);
    }
    .homeSliderCar .sliderBody .slider .homeMainSlider .swiper-slide:nth-child(2) {
        background: linear-gradient(325deg, rgba(0, 9, 14, 0) 0%, rgba(0, 9, 18, 1) 100%), url(../../Img/portrait-two-business-people-discussing-work-meeting-luxurious-hotel-lobby-copy-space.jpg);
    }
    .homeSliderCar .sliderBody .slider .homeMainSlider .swiper-slide:nth-child(3) {
        background: linear-gradient(325deg, rgba(0, 9, 14, 0) 0%, rgba(0, 9, 18, 1) 100%), url(../../Img/woman-with-big-folder-posing-office.jpg);
    }
    .hrimg {
      width: 100%;
    }
  
  .swiper-pagination-bullet {
    border: 1px solid var(--color-primary);
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--color-primary);
  }

  @media only screen and (max-width: 992px) {
    .homeSliderCar .sliderBody .slider .homeMainSlider .swiper-slide {
      height: 800px;
      max-height: fit-content;
    }
    }
    @media only screen and (max-width: 767px) {
      /* .homeSliderCar .sliderBody .slider .homeMainSlider .swiper-slide .sliderItem {
        padding: 200px 30px 100px 50px;
        } */
        .homeSliderCar .sliderBody .slider .homeMainSlider .swiper-slide {
          padding: 0;
          padding-top: 150px;
          }
            .homeSliderCar .sliderBody .slider .homeMainSlider .swiper-slide {
              height: 650px;
              max-height: fit-content;
            }
  }
  
  /* End Hero BG Slider */