
/*----------------------------------------*/
/*  02. Header CSS
  /*----------------------------------------*/
/*--
  /*  2.1 - Header Top CSS
  /*----------------------------------------*/
  .header-section {
    position: absolute;
    left: 0;
    top: 0;
    background: #ffffff;
    width: 100%;
    z-index: 999;
  }
  
  @media only screen and (max-width: 991px) {
    .header-section {
      padding: 15px 0;
    }
  }
  
  .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-logo a img {
    width: 100px;
  }
  
  @media only screen and (max-width: 1199px) {
    .header-logo a img {
      width: 100px;
    }
  }
  
  .header-menu .main-menu {
    display: flex;
    list-style: none;
    margin: 0;
  }
  
  .header-menu .main-menu li {
    position: relative;
    padding: 30px 25px;
  }
  
  @media only screen and (max-width: 1199px) {
    .header-menu .main-menu li {
      padding: 30px 20px;
    }
  }
  
  .header-menu .main-menu li a {
    font-size: 15px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 700;
    text-transform: capitalize;
    color: #0e0e0e;
    text-decoration: none;
  }
  
  .header-menu .main-menu li > a.active {
    color: var(--color-primary);
  }
  
  .header-menu .main-menu li.active-menu > .menu-icon::after {
    color: var(--color-primary);
  }
  
  .header-menu .main-menu li .menu-icon::after {
    content: "\f16c";
    font-family: "flaticon";
    font-size: 10px;
    color: #94a0b9;
    margin-left: 5px;
    transition: all 0.3s linear;
  }
  
  .header-menu .main-menu li:hover > .menu-icon::after {
    color: var(--color-primary);
  }
  
  .header-menu .main-menu li:hover > a {
    color: var(--color-primary);
  }
  
  .header-menu .main-menu li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
  }
  
  .header-menu .main-menu li .sub-menu {
    position: absolute;
    left: 0;
    top: 110%;
    width: 270px;
    background: #ffffff;
    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
    padding: 20px 0;
    border-radius: 5px;
    border-top: 3px solid var(--color-primary);
    opacity: 0;
    z-index: 99;
    visibility: hidden;
    transition: all 0.3s linear;
    list-style: none;
  }
  
  .header-menu .main-menu li .sub-menu li {
    padding: 0;
  }
  
  .header-menu .main-menu li .sub-menu li a {
    padding: 5px 25px;
    font-size: 15px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 600;
    text-transform: capitalize;
    display: block;
    position: relative;
    z-index: 1;
  }
  
  .header-menu .main-menu li .sub-menu li.active > a {
    color: var(--color-primary);
  }
  
  .header-menu .main-menu li .sub-menu li:hover > a {
    color: var(--color-primary);
  }
  
  .header-toggle {
    margin-left: 30px;
  }
  
  .header-toggle button {
    background: none;
    border: 0;
    padding: 0;
  }
  
  .header-toggle button span {
    width: 25px;
    height: 2px;
    background: var(--color-primary);
    margin: 5px 0;
    display: block;
  }
  
  .header-toggle button span:last-child {
    margin-bottom: 0;
  }
  
  .header-section-02 {
    background: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .wo-home {
    backdrop-filter: blur(10px);
  }
  .header-section-02.sticky {
    background: #ffffff;
    border-bottom: 0;
  }
  
  .header-section-02.sticky .header-wrap .header-logo .logo-black {
    display: block;
  }
  
  .header-section-02.sticky .header-wrap .header-logo .logo-white {
    display: none;
  }
  
  .header-section-02.sticky .header-wrap .header-menu .main-menu li a {
    color: #0e0e0e;
  }
  
  .header-section-02.sticky
    .header-wrap
    .header-menu
    .main-menu
    li
    > a.active {
    color: var(--color-primary);
  }
  
  .header-section-02.sticky
    .header-wrap
    .header-menu
    .main-menu
    li.active-menu
    > .menu-icon::after {
    color: var(--color-primary);
  }
  
  .header-section-02.sticky
    .header-wrap
    .header-menu
    .main-menu
    li
    .menu-icon::after {
    color: #94a0b9;
  }
  
  .header-section-02.sticky
    .header-wrap
    .header-menu
    .main-menu
    li:hover
    > .menu-icon::after {
    color: var(--color-primary);
  }
  
  .header-section-02.sticky .header-wrap .header-menu .main-menu li:hover > a {
    color: var(--color-primary);
  }
  
  .header-section-02.sticky .header-wrap .header-menu .main-menu li .sub-menu {
    border-top: 3px solid var(--color-primary);
  }
  
  .header-section-02.sticky
    .header-wrap
    .header-menu
    .main-menu
    li
    .sub-menu
    li.active
    > a {
    color: var(--color-primary);
  }
  
  .header-section-02.sticky
    .header-wrap
    .header-menu
    .main-menu
    li
    .sub-menu
    li:hover
    > a {
    color: var(--color-primary);
    padding-left: 35px;
  }
  
  .header-section-02 .header-logo .logo-black {
    display: none;
  }
  
  .header-section-02 .header-menu .main-menu > li > a {
    color: #ffffff;
  }
  
  .header-section-02 .header-menu .main-menu > li > a.active {
    color: var(--color-primary);
  }
  
  .header-section-02
    .header-menu
    .main-menu
    > li.active-menu
    > .menu-icon::after {
    color: var(--color-primary);
  }
  
  .header-section-02 .header-menu .main-menu > li .menu-icon::after {
    color: #ffffff;
  }
  
  .header-section-02 .header-menu .main-menu > li:hover > .menu-icon::after {
    color: var(--color-primary);
  }
  
  .header-section-02 .header-menu .main-menu > li:hover > a {
    color: var(--color-primary);
  }
  
  .header-section-02 .header-menu .main-menu > li .sub-menu {
    border-top: 3px solid var(--color-primary);
  }
  
  .header-section-02 .header-menu .main-menu > li .sub-menu li.active > a {
    color: var(--color-primary);
  }
  
  .header-section-02 .header-menu .main-menu > li .sub-menu li:hover > a {
    color: var(--color-primary);
  }
  
  .sticky {
    position: fixed;
    top: 0;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
    -webkit-animation: sticky 1s;
    animation: sticky 1s;
  }
  
  @-webkit-keyframes sticky {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  
  @keyframes sticky {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  
  .box {
    width: 100%;
    height: 30px;
    background-color: #212529;
    position: relative;
  }
  .box-1 {
    width: 50%;
    background: #fff;
    height: 100%;
    border-radius: 0px 40px 0px 0px;
    border-top: 5px solid var(--color-primary);
    border-right: 5px solid var(--color-primary);
  }
  .box-2 {
    position: absolute;
    background-color: #fff;
    height: 100%;
    width: 50%;
    right: 0;
    top: 0;
    border-radius: 40px 0px 0px 0px;
    border-top: 5px solid var(--color-primary);
    border-left: 5px solid var(--color-primary);
  }


/*--
  /*  2.3 - Header Top CSS
  /*----------------------------------------*/
  .offcanvas {
    background: #000c29 !important;
    width: 320px;
    /* display: none; */
    top: 0;
    right: 0;
  }
  
  @media only screen and (max-width: 575px) {
    .offcanvas {
      width: 280px;
    }
  }
  .offcanvas-header {
    justify-content: space-between;
  }
  .offcanvas-header .close-btn {
    background: none;
    border: 0;
    font-size: 18px;
    color: #ffffff;
    transition: all 0.3s linear;
  }
  
  .offcanvas-header .close-btn:hover {
    transform: rotate(90deg);
    color: var(--color-primary);
  }
  
  .offcanvas-logo a img {
    width: 100px;
  }
  
  .offcanvas-menu {
    padding: 30px 0;
  }
  
  .offcanvas-menu .main-menu {
    list-style: none;
    padding-left: 0;
  }
  
  .offcanvas-menu .main-menu li {
    position: relative;
  }
  
  .offcanvas-menu .main-menu li a {
    display: block;
    font-size: 16px;
    font-weight: 600;
    /* font-family: "Barlow", sans-serif; */
    color: #ffffff;
    padding: 5px 0px;
    position: relative;
    text-decoration: none;
  }
  .offcanvas-menu .main-menu li a.active {
    color: var(--color-primary);
  }
  
  .offcanvas-menu .main-menu li .menu-expand {
    position: absolute;
    right: 20px;
    top: 2px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
  }
  
  .offcanvas-menu .main-menu li .menu-expand::before {
    position: absolute;
    content: "";
    width: 16px;
    height: 2px;
    background-color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s linear;
  }
  
  .offcanvas-menu .main-menu li .menu-expand::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 16px;
    left: 50%;
    top: 50%;
    bottom: 0;
    background-color: #ffffff;
    transform: translate(-50%, -50%);
    transition: all 0.3s linear;
  }
  
  .offcanvas-menu .main-menu li:hover > a {
    color: var(--color-primary);
  }
  
  .offcanvas-menu .main-menu li.active > .menu-expand::after {
    height: 0;
  }
  
  .offcanvas-menu .main-menu li ul {
    padding-left: 6%;
    display: none;
  }

  .offcanvas.show {
    top: 0;
    right: 0;
  }

