.harsafe-choose-us-section-02 .choose-us-right {
    padding-left: 10px;
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-choose-us-section-02 .choose-us-right .faq-accordion {
      margin-top: 50px;
    }
  }
  
  .harsafe-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item {
    border: 0;
    box-shadow: 0px 0px 161px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    margin-top: 35px;
  }
  
  .harsafe-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button {
    padding: 25px 35px;
  }
  
  .harsafe-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button .title {
    font-size: 20px;
    color: #0e0e0e;
    /* font-family: "Barlow", sans-serif; */
    display: inline-block;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
  }
  
  .harsafe-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button::after {
    position: absolute;
    content: "+";
    border: none;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    border-radius: 50%;
    color: #ffffff;
    background: linear-gradient(120deg, var(--color-primary) 0%, #ff00b1 100%);
    right: 35px;
    transition: all .3s ease-out 0s;
  }
  
  .harsafe-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-body {
    font-size: 16px;
    border-top: 1px solid #ebebeb;
    padding: 30px 35px;
  }
  
  .harsafe-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button:focus {
    box-shadow: none;
  }
  
  .harsafe-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button:not(.collapsed) {
    background-color: #ffffff;
    box-shadow: none;
  }
  
  .harsafe-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item .accordion-button:not(.collapsed)::after {
    position: absolute;
    content: "-";
    border: none;
    width: 30px;
    height: 30px;
    line-height: 24px;
    text-align: center;
    background: #e6eef8;
    color: #0e0e0e;
    transform: rotate(0);
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-choose-us-section-02 {
      padding-bottom: 350px;
    }
    .harsafe-choose-us-section-02 .choose-us-left {
      padding-right: 0;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-choose-us-section-02 {
      padding-bottom: 330px;
    }
    .harsafe-choose-us-section-02 .choose-us-right {
      padding-left: 0;
      padding-top: 15px;
    }
  }
  
  .choose-us-item-02 {
    background: #ffffff;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: 40px 45px 40px;
    margin-top: 30px;
    position: relative;
  }
  
  .choose-us-item-02 .choose-us-img img {
    transition: all 0.3s linear;
  }
  
  .choose-us-item-02 .choose-us-content .title {
    font-size: 22px;
    line-height: 30px;
    margin-top: 20px;
  }
  
  .choose-us-item-02 .choose-us-content p {
    font-size: 16px;
    line-height: 30px;
    padding-top: 20px;
  }
  
  .choose-us-item-02:hover .choose-us-img img {
    transform: scale(0.9);
  }
  
  .harsafe-choose-us-section-03 {
    background: #ffffff;
    padding-top: 120px;
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-choose-us-section-03 {
      padding-top: 100px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-choose-us-section-03 {
      padding-top: 80px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .harsafe-choose-us-section-03 {
      padding-top: 60px;
    }
  }
  
  .harsafe-choose-us-section-04 {
    background: #ffffff;
    padding-top: 120px;
  }
  
  .harsafe-choose-us-section-04 .choose-us-wrap {
    margin-top: -30px;
  }
  
  .harsafe-choose-us-section-04 .choose-us-content-wrap {
    padding-top: 0;
  }
  
  .harsafe-choose-us-section-04 .choose-us-item-02 {
    border: 1px solid #e1e1e1;
    box-shadow: none;
  }