

.partnerPayments_outer {
    width: 100%;
    display: block;
    padding-top: 70px;
}
.partnerPayments_outer .partnerPayments_outer_inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    padding: 20px 0;
    align-items: center;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_Left {
    width: 55%;
    background: #fff;
    position: relative;
    padding: 16px 70px 16px 25px;
    border-radius: 13px 26px 26px 13px;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_Left:before {
    position: absolute;
    content: "";
    left: -5px;
    top: -5px;
    bottom: -5px;
    right: -5px;
    background: linear-gradient(-180deg,#ff00b1 0,var(--color-primary) 100%);
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    z-index: -1;
    border-radius: 15px 30px 30px 15px;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_Left h2 {
    font-size: 46px;
    font-weight: 700;
    color: #222;
    display: block;
    margin: 0 0 20px;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_Left h2 p {
    font-size: 25px;
    font-weight: 400;
    color: #191a1c;
    display: block;
    margin: 0;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_Left h2 span.partnerPayment_Large {
    display: block;
    font-size: 70px;
    font-weight: 700;
    background: linear-gradient(-180deg,#ff00b1 0,var(--color-primary) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 50px;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_Left h2 b {
    font-weight: 700;
    background: linear-gradient(-180deg,#ff00b1 0,var(--color-primary) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_Left span {
    font-size: 22px;
    font-weight: 500;
    color: #191a1c;
    display: block;
    width: 100%;
    line-height: normal;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right {
    width: calc(45% + 40px);
    text-align: right;
    position: relative;
    left: -40px;
    top: -10px;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right:before {
    position: absolute;
    content: "";
    left: -90px;
    top: 44px;
    width: 52px;
    height: 34px;
    /* background: url(../../Img/sitlogo.png) no-repeat; */
    background-size: contain;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right .slogen_Title {
    max-width: 60%;
    margin: auto;
    background: linear-gradient(-180deg,#ff00b1 0,var(--color-primary) 100%);
    padding: 10px;
    border-radius: 10px 10px 0 0;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right .slogen_Title span {
    font-weight: 600;
    color: #fff;
    font-size: 16px !important;
    display: block !important;
    text-align: center;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin: 0 0 0;
    padding: 0;
    list-style: none;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right ul li {
    display: block;
    margin: auto;
    width: 70%;
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right ul li span {
    border: solid 17px #fff;
    box-shadow: 0px 0px 4px rgb(0, 0, 0, 27%);
    border-radius: 0 0 80px 0;
    /* background: #fff3e7; */
    transition: all .5s ease-in;
    display: flex;
    padding: 0 30px;
    }
    .partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right ul li:hover span {
        box-shadow: 0px 0px 27px rgb(0, 0, 0, 27%);
    }
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right ul li span img {
    /* border-radius: 0 0 60px 0; */
    width: 100%;
}


@media only screen and (max-width: 991px) {
.partnerPayments_outer {
    padding: 30px 0
}

.partnerPayments_outer .partnerPayments_outer_inner {
    padding: 20px 0;
    flex-wrap: wrap
}

.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_Left {
    width: 100%;
    padding: 20px;
    text-align: center
}

.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right {
    width: 100%;
    left: 0;
    top: 20px;
    text-align: center
}

.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right ul {
    margin: 0 auto 30px;
    max-width: 100%
}
.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right ul li {
    width: 100%;
}
.partnerPayments_outer .partnerPayments_outer_inner {
    padding: 20px;
    flex-wrap: wrap;
    gap: 10px
}

.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right .slogen_Title {
    max-width: 100%
}

.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right:before {
    display: none
}

.partnerPayments_outer .partnerPayments_outer_inner .partnerPayments_outer_inner_right .slogen_Title {
    top: 5px
}
}






















