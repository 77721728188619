

.footer-section-04 {
    background: url(../../Img/bg/footer-bg3.jpg);
}

.footer-widget-wrap {
    padding-top: 70px;
    padding-bottom: 75px;
  }
  
  .footer-widget {
    margin-top: 30px;
  }
  
  .footer-logo {
    display: block;
    max-width: 110px;
  }
  
  .footer-logo img{
    width: 100%;
  }
  
  .widget-info {
    padding-top: 20px;
  }
  .widget-info ul {
    padding: 0;
  }
  
  .widget-info ul li {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 10px;
    transition: all 0.3s linear;
  }
  
  .widget-info ul li .info-icon i {
    display: inline-block;
    color: #0e0e0e;
    font-size: 18px;
    margin-right: 10px;
    margin-top: 2px;
    transition: all 0.3s linear;
  }
  
  .widget-info ul li .info-text {
    font-size: 16px;
    line-height: 20px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 600;
    color: #0e0e0e;
    transition: all 0.3s linear;
  }
  .widget-info ul li .info-text a {
    color: #0e0e0e;
    text-decoration: none;
  }
  .widget-info ul li:hover {
    padding-left: 10px;
  }
  .widget-info ul li:hover .info-icon i,
  .widget-info ul li:hover .info-text,
  .widget-info ul li:hover .info-text a {
  color: var(--color-primary);
  }
  
  .footer-widget-about {
    margin-top: 15px;
  }
  
  .footer-widget-about p {
    font-size: 14px;
    line-height: 30px;
    color: #0e0e0e;
    padding-right: 110px;
    margin-top: 20px;
    font-weight: 500;
  }
  
  @media only screen and (max-width: 1399px) {
    .footer-widget-about p {
      padding-right: 0;
    }
  }
  
  .footer-social .social {
    margin-top: 25px;
    padding: 0;
  }
  
  .footer-social .social li {
    display: inline-block;
  }
  
  .footer-social .social li + li {
    margin-left: 5px;
  }
  
  .footer-social .social li a {
    width: 35px;
    height: 35px;
    line-height: 37px;
    text-align: center;
    border-radius: 50%;
    font-size: 16px;
    background: #ffffff;
    color: var(--color-primary);
    display: block;
  }
  
  .footer-social .social li a:hover {
    scale: 1.2;
  }
  
  .footer-widget .footer-widget-title {
    font-size: 20px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 600;
    color: var(--color-primary);
  }
  
  .widget-link .link li {
    margin-top: 7px;
  }
  
  .widget-link .link li a {
    font-size: 14px;
    color: #0e0e0e;
    text-decoration: none;
    transition: all 0.3s linear;
    font-weight: 500;
  }
  
  .widget-link .link li a:hover {
    color: var(--color-primary);
    padding-left: 10px;
  }
  
  .footer-section-04 .widget-link {
    padding-top: 22px;
  }
  .footer-section-04 .widget-link .link {
    list-style: none;
    padding: 0;
  }
  

  @media only screen and (max-width: 576px) {
    .footer-widget, .widget-info {
      text-align: center;
    }
    .widget-info ul li {
      justify-content: center;
    }
  }

