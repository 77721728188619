

.harsafe-cta-section-04 {
    /* background: #0f0948; */
    background: url(../../../Img/bg/choose-us-bg3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  
  .harsafe-cta-section-04 .cta-left {
    padding-right: 115px;
  }
  
  .harsafe-cta-section-04 .cta-left .section-title .title {
    position: relative;
    padding-left: 40px;
    color: var(--color-primary);
  }
  
  .harsafe-cta-section-04 .cta-left .section-title .title::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 50%;
    background: linear-gradient(-160deg, #ff00b1 0%, var(--color-primary) 100%);
    width: 5px;
    height: 100%;
    transform: translateY(-45%);
  }
  .harsafe-cta-section-06 .cta-info .cta-icon img {
    filter: invert(1);
  }
  .harsafe-cta-section-06 .cta-info .cta-text p {
    font-size: 18px;
    line-height: 30px;
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    color: #0e0e0e;
  }
  .harsafe-cta-section-06 .cta-info .cta-text .number a{
    font-size: 36px;
    line-height: 42px;
    color: var(--color-primary);
    text-decoration: none;
  }