
.harsafe-service-section .icon-box {
    padding: 57px 55px 55px;
    background: #f5f5f5;
    height: 100%;
    transition: all 1s;
  }
  .harsafe-service-section .icon-box:hover {
    background: #ffffff;
    box-shadow: 0px 0px 25px #d9d9d9;
  }
  .harsafe-service-section .service-icon {
    margin: auto;
    margin-bottom: 21px;
    height: 100px;
  }
  .harsafe-service-section .service-icon img {
    height: 100%;
  }
  .harsafe-service-section h6 {
    margin-bottom: 15px;
    margin-top: 5px;
  }
  .harsafe-service-section h6 a {
    color: #1f2732;
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    text-decoration: none;
  }
  .harsafe-service-section p {
    margin-bottom: 20px;
    color: #7f7f7f;
    font-size: 18px;
  }
  .btn-link {
    color: var(--color-primary);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-decoration: none !important;
    padding: 12px 20px;
    background-color: #ffffff;
    border: 1px solid var(--color-primary);
    border-radius: 7px;
  }
  .btn-link:hover {
    color: #ffffff !important;
    background: var(--color-primary);
  }