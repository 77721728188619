

.contact-info-section .contact-info-wrap {
    margin-top: -30px;
  }
  
  .contact-info-section .contact-info-wrap .single-contact-info {
    background: #f8f8f8;
    padding: 50px 80px 75px;
    border-radius: 5px;
    margin-top: 30px;
    height: 100%;
  }
  
  @media only screen and (max-width: 1199px) {
    .contact-info-section .contact-info-wrap .single-contact-info {
      padding: 50px 40px 60px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .contact-info-section .contact-info-wrap .single-contact-info {
      padding: 50px 30px 60px;
    }
  }
  
  .contact-info-section .contact-info-wrap .single-contact-info .info-content .title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .contact-info-section .contact-info-wrap .single-contact-info .info-content p,
  .contact-info-section .contact-info-wrap .single-contact-info .info-content p a {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    color: #757575;
    text-decoration: none;
  }
  
  @media only screen and (max-width: 1199px) {
    .contact-info-section .contact-info-wrap .single-contact-info .info-content p {
      font-size: 16px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .contact-info-section .contact-info-wrap .single-contact-info .info-content p {
      font-size: 18px;
    }
  }
  
  
  .contact-map-section .contact-map-wrap {
    margin-bottom: -8px;
  }
  
  .contact-map-section .contact-map-wrap iframe {
    height: 550px;
    width: 100%;
    filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
  }

  

  /* Map Section  */

  .contact-map-section .contact-map-wrap {
    margin-bottom: -8px;
  }
  
  .contact-map-section .contact-map-wrap iframe {
    height: 550px;
    width: 100%;
    filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
  }

  .map-padding {
    padding-top: 120px;
  }

