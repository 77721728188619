.pt-60 {
    padding-top: 60px !important;
}
.lt-ued {
    margin-top: 40px;
}
.cb-challenges {
    background: #fff;
      padding: 30px;
      height: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 8px 0px var(--color-primary-20);
      text-align: center;
  }
  
  .cb-challenges:hover {
    /* background: rgba(154,101,254,.1); */
    box-shadow: 0 4px 2px var(--color-primary) ;
    cursor: default;
    border-top: 1px solid var(--color-primary);
  }
  .chicon-color {
      background-image: url(../../../Img/shape/iconbgcount.svg);
      background-size: cover;
      background-repeat: no-repeat;
      height: 75px;
      width: 75px;
      margin: 0 0 15px 0;
      text-align: center;
      line-height: 75px;
      color: var(--color-primary);
      font-size: 28px;
      display: inline-block;
  }
  
  .chicon-color im {
    font-size: 40px;
    font-weight: bold;
    margin: 20px auto;
    color: #00BCF2;
    background-color: #fff;
    padding: 12px 20px;
    border-radius: 50%;
  }
  .chicon-color img {
    height: 60px;
    filter: invert(45%) sepia(251%) saturate(6854%) hue-rotate(201deg) brightness(66%) contrast(100%) !important;
  }
    .cb-challenges h5 {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 700;
        /* color: var(--color-primary); */
    }
    .cb-challenges p {
        color: #47464a;
    }
    .cb-challenges h5 span {
        margin: 6px auto 0;
        border-bottom: solid 2px var(--color-primary);
        display: block;
        width: 75px;
        text-align: center;
    }
  