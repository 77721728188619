
.harsafe-skill-sectionBG {
  background: url(../../../Img/bg/skill-bg.jpg);
}
.harsafe-skill-section .skill-left {
    padding-right: 120px;
  }
  
  .harsafe-skill-section .skill-left p {
    font-size: 16px;
    line-height: 30px;
    margin-top: 40px;
  }
  
  .harsafe-skill-section .skill-left .skill-author {
    margin-top: 30px;
  }
  
  .harsafe-skill-section .skill-left .skill-author .name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 10px;
  }
  
  .harsafe-skill-section .skill-left .skill-author .designation {
    font-size: 12px;
    line-height: 18px;
  }
  
  .harsafe-skill-section .skill-right {
    padding-right: 50px;
    margin-top: 30px;
  }
  
  .harsafe-skill-section .skill-right .experience {
    display: flex;
  }
  
  .harsafe-skill-section .skill-right .experience .number {
    font-size: 180px;
    line-height: 30px;
    color: #ff5538;
    margin-right: 10px;
  }
  
  .harsafe-skill-section .skill-right .experience span {
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    margin-top: 5px;
  }
  
 .skill-wrap .skill-wrap .counter-bar {
    padding-top: 0px;
  }
  
  .skill-wrap .skill-wrap .counter-bar .skill-item {
    margin-top: 30px;
  }
  
  .skill-wrap .counter-bar .skill-item .title {
    font-size: 14px;
    line-height: 45px;
    /* font-family: "Barlow", sans-serif; */
    font-weight: 600;
    color: #120e1d;
  }
  
  .skill-wrap .counter-bar .skill-item .skill-bar .bar-inner {
    width: 100%;
    height: 6px;
    border-radius: 0px;
    background-color: transparent;
    position: relative;
  }
  
 .skill-wrap .counter-bar .skill-item .skill-bar .bar-inner .progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 6px;
    border-radius: 0px;
    background: var(--color-primary);
    transition: 3s;
    transition-delay: 1s;
    width: 0;
  }
  
  .skill-wrap .counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent {
    position: absolute;
    top: -45px;
    right: -30px;
    font-size: 12px;
    color: #ffffff;
  }
  
  .counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent::after {
    content: "";
    position: absolute;
    left: -8px;
    top: -2px;
    width: 40px;
    height: 25px;
    background: #0e0e0e;
    line-height: 20px;
    text-align: center;
    z-index: -1;
  }
  
  .counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent::before {
    position: absolute;
    content: "";
    border-top: 20px solid #0e0e0e;
    width: 0;
    height: 0;
    bottom: -12px;
    left: 0;
    border-right: 15px solid transparent;
    transform: translateX(-50%);
    z-index: -1;
  }
  .counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent .counter {
    text-align: center;
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-skill-section .skill-left {
      padding-right: 30px;
    }
    .harsafe-skill-section .skill-right .experience .number {
      font-size: 150px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-skill-section .skill-left {
      padding-right: 0px;
      margin-bottom: 50px;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .harsafe-skill-section .skill-right {
      padding-right: 0px;
    }
    .harsafe-skill-section .counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent {
      right: -15px;
    }
  }
  
  .harsafe-skill-section-02 {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 5;
    overflow: hidden;
  }
  
  .harsafe-skill-section-02 .skill-left {
    padding-right: 25px;
  }
  
  .harsafe-skill-section-02 .skill-left .experience-wrap {
    display: flex;
    margin-top: 35px;
  }
  
  .harsafe-skill-section-02 .skill-left .experience-wrap .experience {
    max-width: 170px;
    background: linear-gradient(-120deg, var(--color-primary) 0%, #ff00b1 100%);
    padding: 30px;
  }
  
  .harsafe-skill-section-02 .skill-left .experience-wrap .experience .number,
  .harsafe-skill-section-02 .skill-left .experience-wrap .experience .number span {
    font-size: 80px;
    line-height: 54px;
    font-weight: 600;
    color: #ffffff;
  }
  
  .harsafe-skill-section-02 .skill-left .experience-wrap .experience span {
    font-size: 18px;
    line-height: 24px;
    margin-top: 6px;
    display: inline-block;
    color: #ffffff;
  }
  
  .harsafe-skill-section-02 .skill-left .experience-wrap .experience-text {
    flex: 1;
    margin-left: 55px;
  }
  
  .harsafe-skill-section-02 .skill-left .experience-wrap .experience-text p {
    font-size: 18px;
    line-height: 30px;
    margin-top: 0;
  }
  
  .harsafe-skill-section-02 .skill-left .experience-wrap .experience-text .learn-more {
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    color: var(--color-primary);
    margin-top: 20px;
    text-decoration: none;
  }
  
  .harsafe-skill-section-02 .skill-left .experience-wrap .experience-text .learn-more i {
    position: relative;
    top: 1px;
    transition: all 0.3s linear;
  }
  
  .harsafe-skill-section-02 .skill-left .experience-wrap .experience-text .learn-more:hover i {
    margin-left: 5px;
  }
  
  .harsafe-skill-section-02 .skill-right {
    margin-top: 0;
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-skill-section-02 .skill-left {
      padding-right: 0;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-skill-section-02 .skill-right {
      padding-top: 20px;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .harsafe-skill-section-02 .skill-left .experience-wrap {
      flex-direction: column;
      gap: 25px;
    }
    .harsafe-skill-section-02 .skill-left .experience-wrap .experience-text {
      margin-left: 0px;
    }
  }
  
  .harsafe-skill-section-03 .skill-left {
    padding-right: 60px;
  }
  
  .harsafe-skill-section-03 .skill-left .about-list {
    margin-top: 20px;
  }
  
  .harsafe-skill-section-03 .skill-left .about-author-info-wrap {
    margin-right: 30px;
  }
  
  .harsafe-skill-section-03 .skill-right {
    padding-right: 120px;
  }
  
  .harsafe-skill-section-03 .skill-right .text {
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 30px;
  }
  
  @media only screen and (max-width: 1199px) {
    .harsafe-skill-section-03 .skill-left {
      padding-right: 30px;
    }
    .harsafe-skill-section-03 .skill-right {
      padding-right: 0;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .harsafe-skill-section-03 .skill-left {
      padding-right: 0px;
    }
    .harsafe-skill-section-03 .skill-left .about-author-info-wrap {
      margin-right: 0px;
    }
  }
















  :root{ 
    --white: #fff;
    --color1: #e1b12c;
    --color2: #fbc531;
}
.skill-wrap .counter{
  background: var(--white);
  width: 200px;
  height: 200px;
  border-radius: 100px 100px;
  position: relative;
  box-shadow: inset 0 0 11px rgba(0,0,0,0.5);
  z-index: 1;
  font-family: "Hind", sans-serif;
  padding: 36px 10px;
  text-align: center;
  border: 10px solid var(--color1);
margin: 0 auto;
color: var(--color1);
  }
  
 .skill-wrap .counter:before{
    content: "";
    color: var(--color1);
    position: absolute;
    width: 226px;
    height: 226px;
    /* transform: translateX(-50%) translateY(-50%); */
    top: -13%;
    left: -13%;
    border-radius: 50%;
    border-top: 8px solid transparent;
    border-right: 8px solid var(--color1);
    border-bottom: 8px solid transparent;
    border-left: 10px solid var(--color1);
    z-index: -1;
    animation: round-01 4s linear infinite;
}


.skill-wrap .codfgunter:after{
    content:"";
    background: var(--color1);
    width: 100%;
    height: 100%;
    border-radius: 100px 100px;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 0 6px rgba(0,0,0,0.5);
    z-index: -1;
}
.skill-wrap .counter:after{
    background: var(--white);
    width: calc(100% - 18px);
    height: calc(100% - 18px); 
 
}

@keyframes round-01 {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.skill-wrap .counter .counter-icon{
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 15px;
}
.skill-wrap .counter h3{
    font-size: 19px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 10px;
}
.skill-wrap .counter .counter-value{
    font-size: 33px;
    font-weight: 600;
    line-height: 33px;
    display: block;
}
.skill-wrap .counter.green{ 
    --color1: #44bd32;
    --color2: #4cd137; 
}
.skill-wrap .counter.purple{ 
    --color1:#8c7ae6;
    --color2:#9c88ff; 
}
.skill-wrap .counter.blue{ 
    --color1: #0097e6;
    --color2: #00a8ff; 
}
@media screen and (max-width:990px){
    .counter{ margin-bottom: 40px; }
}   
  